import axios from 'axios'

export default {
  namespaced: true,

  state: {
    selecteContact: undefined,
  },

  mutations: {
    subscriptionSuccess(state, user) {
      this.state.auth.user = user
    },
  },

  actions: {
    getPlan({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/loops/plan`)
          .then((res) => {
            resolve(res.data.data.plan)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    subscription({ commit }, couponCode) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/loops/subscription?coupon=${couponCode}`)
          .then((res) => {
            commit('subscriptionSuccess', res.data.data.user)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    checkCoupon({ commit }, coupon) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/loops/check-coupon?code=${coupon}`)
          .then((res) => {
            resolve(res.data.data.coupon)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
