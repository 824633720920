import Vue from 'vue'
import store from '../store/index'
import moment from 'moment-timezone'

Vue.filter('datetime', function (value, timeFormat, timezone = null) {
  if (!value) return ''
  let user = store.getters['auth/user'];
  if (!timezone && user && user.business)
  {
    timezone = user.business.timezone
  }
  if (!timezone) return ''
  return moment(value).tz(timezone).format(timeFormat)
})
