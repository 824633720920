import Vue from 'vue'
import axios from 'axios'

const initState = {
  users: null,
  queries: null,
  totalUsers: 0,
  notifications: [],
  allNotificaiton: [],
}

export default {
  namespaced: true,
  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { users, queries, totalUsers }) {
      state.users = users
      state.queries = queries
      state.totalUsers = totalUsers
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },

    getNotificationsSuccess(state, notifications) {
      state.notifications = notifications
    },

    getAllNotificationsSuccess(state, allNotificaiton) {
      state.allNotificaiton = allNotificaiton
    },

    readNotificationSuccess(state, noticeId) {
      state.notifications = state.notifications.filter((notice) => {
        return notice.id !== noticeId
      })

      state.allNotificaiton = state.allNotificaiton.map((notice) => {
        if (notice.id === noticeId) {
          notice.read = true
        }
        return {
          ...notice,
        }
      })
    },

    deleteNotificationSuccess(state, noticeId) {
      state.notifications = state.notifications.filter((notice) => {
        return notice.id !== noticeId
      })

      state.allNotificaiton = state.allNotificaiton.filter((notice) => {
        return notice.id !== noticeId
      })
    },

    markAllNotificationAsReadSuccess(state) {
      state.notifications = []

      state.allNotificaiton = state.allNotificaiton.map((notice) => {
        notice.read = true
        return notice
      })
    },

    updateUnread(state, unread) {
      this.state.auth.user.business.unread = unread
    },
    
    getLiveDataSuccess(state, data) {
      this.state.auth.user.business.unread = data.unread
    },

    updateTwilioSuccess(state, twilioConfig) {
      this.state.auth.user.twilioConfig = twilioConfig
    },

    updateSendgridSuccess(state, sendgridIntegration) {
      this.state.auth.user.sendgridIntegration = sendgridIntegration
    },

    updateSettingSuccess(state, settings) {
      this.state.auth.user.settings = settings
    },
  },
  actions: {
    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              users: res.data.data,
              queries,
              totalUsers: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    changePassword({ commit }, params) {
      return new Promise((resolve, reject) => {
        let apiPath = params.user_id
          ? `/users/${params.user_id}/change-password`
          : '/change-password'

        axios
          .post(apiPath, params)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getLiveData({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/live-data')
          .then((res) => {
            commit('getLiveDataSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/notifications/list')
          .then((res) => {
            commit('getNotificationsSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAllNotifications({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/notifications')
          .then((res) => {
            commit('getAllNotificationsSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    readNotification({ commit }, noticeId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/notification/${noticeId}/read`)
          .then((res) => {
            commit('readNotificationSuccess', noticeId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteNotification({ commit }, noticeId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/notification/${noticeId}`)
          .then((res) => {
            commit('deleteNotificationSuccess', noticeId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    createNotification({ commit }, notice) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/notifications`, notice)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    markAllNotificationAsRead({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/notifications/mark-all')
          .then((res) => {
            commit('markAllNotificationAsReadSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateUnread({ commit }, unread) {
      commit('updateUnread', unread)
    },

    updateTwilio({ commit }, twilio) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/twilio-config`, twilio)
          .then((res) => {
            commit('updateTwilioSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateSendgrid({ commit }, sendgrid) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/sendgrid-config`, sendgrid)
          .then((res) => {
            commit('updateSendgridSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateSetting({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/settings`, params)
          .then((res) => {
            commit('updateSettingSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sendA2pNotification({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/send-a2p-notification`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    indexQueries(state) {
      return state.queries
    },

    all(state) {
      return state.users
    },

    totalUsers(state) {
      return state.totalUsers
    },

    notifications: (state) => state.notifications,
    allNotificaiton: (state) => state.allNotificaiton,
  },
}
