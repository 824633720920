import axios from 'axios'

export default {
  namespaced: true,

  state: {
    selecteContact: undefined,
    cancelRequest: [],
  },

  mutations: {
    subscriptionSuccess(state, user) {
      this.state.auth.user = user
    },

    selectContact(state, contact) {
      state.selecteContact = contact
    },

    addRequest (state, payload) {
      state.cancelRequest[payload.type] = payload.source;
    },

    cancelRequest (state, type) {
      if (state.cancelRequest[type]) {
        state.cancelRequest[type]();
      }
      state.cancelRequest[type] = null;
    },
  },

  actions: {
    create({ commit }, contact) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat`, contact)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    update({ commit }, contact) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/livechat/${contact.id}`, contact)
          .then((res) => {
            resolve(res.data.data.contact)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    sendMessage({ commit }, message) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat/messages`, message)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getContacts({ commit, state }, queries) {
      return new Promise((resolve, reject) => {
        let requestType = 'getContacts'
        if (state.cancelRequest[requestType])
        {
          commit('cancelRequest', requestType)
          commit('cancelRequest', 'getMessages')
        }
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios
          .get(`/livechat`, { params: queries, cancelToken: source.token })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getMessages({ commit, state }, queries) {
      return new Promise((resolve, reject) => {
        let requestType = 'getMessages'
        if (state.cancelRequest[requestType])
          commit('cancelRequest', requestType)
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios
          .get(`/livechat/messages`, { params: queries, cancelToken: source.token })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getContact({ commit }, contactId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/livechat/${contactId}`)
          .then((res) => {
            resolve(res.data.data.contact)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    searchContacts({ commit }, phone) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/livechat/search-contacts`, { params: { search: phone } })
          .then((res) => {
            resolve(res.data.data.contacts)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    toggleOpen({ commit }, contactId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat/${contactId}/open`)
          .then((res) => {
            resolve(res.data.data.contact)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    toggleRead({ commit }, contactId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat/${contactId}/read`)
          .then((res) => {
            resolve(res.data.data.contact)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    setStatus({ commit }, { contactId, status }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat/${contactId}/status?status=${status}`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getPlan({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/livechat/plan`)
          .then((res) => {
            resolve(res.data.data.plan)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    subscription({ commit }, couponCode) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat/subscription?coupon=${couponCode}`)
          .then((res) => {
            commit('subscriptionSuccess', res.data.data.user)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    businessSubscription({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat/business-subscription`)
          .then((res) => {
            resolve(res.data.data.link)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    checkCoupon({ commit }, coupon) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/livechat/check-coupon?code=${coupon}`)
          .then((res) => {
            resolve(res.data.data.coupon)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },

  getters: {
    selectedContact(state) {
      return state.selecteContact
    },
  },
}
