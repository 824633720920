import axios from 'axios'

export default {
  namespaced: true,
  state: {
    access_token: localStorage.getItem('access_token'),
    loading: false,
    user: null,
    impersonating: false,
  },
  mutations: {
    loginSuccess(state, data) {
      state.access_token = data.access_token
      localStorage.setItem('access_token', data.access_token)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    getProfileSuccess(state, user) {
      state.user = user
      this.dispatch('app/getConfig')
    },

    updateProfileSuccess(state, user) {
      state.user = user
    },

    logout(state) {
      state.impersonating = false
      state.user = null
      state.access_token = null
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('access_token')
      localStorage.removeItem('main_access_token')
      localStorage.removeItem('main_access_role')

      this.dispatch('app/getConfig', location.hostname)
      this.dispatch('user/resetAll')
      this.dispatch('agency/resetAll')
      this.dispatch('business/resetAll')
      this.dispatch('paymentMethod/resetAll')
      this.dispatch('coupon/resetAll')
      this.dispatch('contact/resetAll')
      this.dispatch('automation/resetAll')
      this.dispatch('broadcast/resetAll')
      this.dispatch('campaign/resetAll')
      this.dispatch('subscription/resetAll')
    },

    impersonateSuccess(state, data) {
      if (!localStorage.getItem('main_access_token')) {
        localStorage.setItem('main_access_token', state.access_token)
        localStorage.setItem('main_access_role', state.user && state.user.role || undefined)
      }

      this.dispatch('user/resetAll')
      this.dispatch('business/resetAll')
      this.dispatch('paymentMethod/resetAll')
      this.dispatch('coupon/resetAll')
      this.dispatch('contact/resetAll')
      this.dispatch('automation/resetAll')
      this.dispatch('broadcast/resetAll')
      this.dispatch('campaign/resetAll')

      state.impersonating = true
      state.user = null
      localStorage.setItem('access_token', data.access_token)
      state.access_token = data.access_token
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    registerAgencySuccess(state, { user, res: data } ) {

      // Enable Tracking code 
      try{
        if (typeof window.$FPROM !== 'undefined')
        {
          window.$FPROM.trackSignup({
            email: user.email,
            uid: data.uid
          },() => console.log('Callback received!'));
        }
      }catch(t){}

      state.access_token = data.access_token
      localStorage.setItem('access_token', data.access_token)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    registerAgencyOnetimeSuccess(state, { user, res: data } ) {

      // Enable Tracking code 
      try{
        if (typeof window.$FPROM !== 'undefined')
        {
          window.$FPROM.trackSignup({
            email: user.email,
            uid: data.uid
          },() => console.log('Callback received!'));
        }
      }catch(t){}

      state.access_token = data.access_token
      localStorage.setItem('access_token', data.access_token)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    registerBusinessSuccess(state, data) {
      state.access_token = data.access_token
      localStorage.setItem('access_token', data.access_token)
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + data.access_token
    },

    stopImpersonateSuccess(state, data) {
      this.dispatch('user/resetAll')
      this.dispatch('business/resetAll')
      this.dispatch('paymentMethod/resetAll')
      this.dispatch('coupon/resetAll')
      this.dispatch('contact/resetAll')
      this.dispatch('automation/resetAll')
      this.dispatch('broadcast/resetAll')
      this.dispatch('campaign/resetAll')
      this.dispatch('subscription/resetAll')

      state.impersonating = false
      state.user = null
      localStorage.setItem(
        'access_token',
        localStorage.getItem('main_access_token')
      )
      state.access_token = localStorage.getItem('main_access_token')
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('main_access_token')
      localStorage.removeItem('main_access_token')
      localStorage.removeItem('main_access_role')
    },

    loading(state, loading) {
      this.state.app.loading = loading
    },
  },
  actions: {
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login', credentials)
          .then((res) => {
            commit('loginSuccess', res.data)

            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    forgotPassword({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/passwords/reset', credentials)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    changePassword({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/passwords/change', credentials)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/logout')
          .then((res) => {
            commit('logout')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getOrderPage({ commit }, slug) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/order-page/${slug}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getProfile({ commit }) {
      return new Promise((resolve, reject) => {
        let param = localStorage.getItem('main_access_token') ? `?i=1` : '';
        axios
          .get(`/profile${param}`)
          .then((res) => {
            commit('getProfileSuccess', res.data.data)

            resolve(res.data.data)
          })
          .catch((error) => {
            commit('logout')
            reject(error)
          })
      })
    },

    updateProfile({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .put('/profile', user)
          .then((res) => {
            commit('updateProfileSuccess', res.data.data)

            resolve(true)
          })
          .catch((error) => {
            if (error.response.status === 401) {
              commit('logout')
            }
            reject(error)
          })
      })
    },

    impersonate({ commit }, userId) {
      commit('loading', true)
      return new Promise((resolve, reject) => {
        axios
          .post(`/impersonate/${userId}`)
          .then((response) => {
            commit('loading', false)
            commit('impersonateSuccess', response.data)
            resolve(true)
          })
          .catch((e) => {
            commit('loading', false)
            reject(e)
          })
      })
    },

    stopImpersonate({ commit }) {
      commit('loading', true)
      return new Promise((resolve, reject) => {
        axios
          .delete(`/impersonate`)
          .then((res) => {
            commit('loading', false)
            commit('stopImpersonateSuccess', res.data)
            resolve(true)
          })
          .catch((e) => {
            commit('loading', false)
            reject(e)
          })
      })
    },

    registerAgency({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/order', user)
          .then((res) => {
            commit('registerAgencySuccess', {
              user, 
              res: res.data.data
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    registerAgencyOnetime({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/order-onetime', user)
          .then((res) => {
            commit('registerAgencyOnetimeSuccess', {
              user, 
              res: res.data.data
            })
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    registerBusiness({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/business/order', user)
          .then((res) => {
            commit('registerBusinessSuccess', res.data.data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    validateEmail({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/validate-email?email=${email}`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    verifyToken({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/token/verify', data)
          .then((data) => {
            resolve(data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    unsubscribe({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/unsubscribe', data)
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

  },

  getters: {
    isLoggedIn: (state) => !!state.access_token,
    isImpersonating: (state) =>
      state.impersonating || localStorage.getItem('main_access_token'),
    user: (state) => state.user,
    settings: (state) => state.user && state.user.settings,

    agency(state) {
      return state.user && state.user.business && state.user.business.agency
    },

    business(state) {
      return state.user && state.user.business
    },

    blockedScreen(state) {

      // Check Business Subscription
      if (
        state.user &&
        state.user.role === 'Business'
      ) {
        if (
          !state.user.use_bandwidth &&
          !state.user.business.phone_number &&
          !state.user.business.is_template
        )
          return true
        if (
          !state.user.business.agency &&
          !state.user.subscribed &&
          !state.user.business.free_fee
        )
          return true
      }

      // Check Agency Subscription
      if (
        state.user &&
        state.user.role === 'Agency' &&
        !state.user.subscribed &&
        !state.user.agency.free_fee &&
        !state.user.agency.is_template
      ) {
        return true
      }

      // Check Twilio Config
      // if (
      //   state.user &&
      //   state.user.role === 'Agency' &&
      //   !state.user.agency.is_template &&
      //   (!state.user.agency.twilioConfig ||
      //     (state.user.agency.twilioConfig &&
      //       !state.user.agency.twilioConfig.token))
      // ) {
      //   return true
      // }

      return false
    },

    dateFormat(state) {
      if (state.user && state.user.business) {
        return state.user.business.date_format
      }

      return 'MM/DD/YYYY'
    },

    birthdayFormat(state, getters) {
      return getters.dateFormat.replace(/(YYYY\/|\/YYYY)/, '');
    },

    orderPageScript() {
      return `<script type="text/javascript">!function(){var geq=window.geq=window.geq||[];if(geq.initialize) return;if (geq.invoked){if (window.console && console.error) {console.error("GE snippet included twice.");}return;}geq.invoked = true;geq.methods = ["page", "suppress", "trackOrder", "identify", "addToCart"];geq.factory = function(method){return function(){var args = Array.prototype.slice.call(arguments);args.unshift(method);geq.push(args);return geq;};};for (var i = 0; i < geq.methods.length; i++) {var key = geq.methods[i];geq[key] = geq.factory(key);}geq.load = function(key){var script = document.createElement("script");script.type = "text/javascript";script.async = true;if (location.href.includes("vge=true")) {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js?v=" + Math.random();} else {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js";}var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};geq.SNIPPET_VERSION = "1.5.1";geq.load("WN2H804");}();</script><script>geq.page()</script>`
    },

    orderPageScript1() {
      return `<script type="text/javascript">
      (function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://cdn.firstpromoter.com/fprom.js',t.onload=t.onreadystatechange=function(){var t=this.readyState;if(!t||"complete"==t||"loaded"==t)try{$FPROM.init("zqul3cac",".customerengine.io")}catch(t){}};var e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)})();</script>`
    },

    orderPageScriptThankyou() {
      return `<script type="text/javascript">
      !function(){var geq=window.geq=window.geq||[];if(geq.initialize) return;if (geq.invoked){if (window.console && console.error) {console.error("GE snippet included twice.");}return;}geq.invoked = true;geq.methods = ["page", "suppress", "trackOrder", "identify", "addToCart"];geq.factory = function(method){return function(){var args = Array.prototype.slice.call(arguments);args.unshift(method);geq.push(args);return geq;};};for (var i = 0; i < geq.methods.length; i++) {var key = geq.methods[i];geq[key] = geq.factory(key);}geq.load = function(key){var script = document.createElement("script");script.type = "text/javascript";script.async = true;if (location.href.includes("vge=true")) {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js?v=" + Math.random();} else {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js";}var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};geq.SNIPPET_VERSION = "1.5.1";
      geq.load("WN2H804");}();</script><script>geq.suppress()</script>`
    },

    customFields(state) {
      return state.user && state.user.business && state.user.business.custom_fields
    },

    allowEmailIntegration: (state) => {
      return true
      // return process.env.NODE_ENV !== 'production' || state.user && state.user.role === 'Business' && state.user.business && [1,2,3].includes(state.user.business.agency_id)
    }
  },
}
