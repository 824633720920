import Vue from 'vue'
import axios from 'axios'

const initState = {
    currentPage: 'index',
    keywords: null,
    queries: {
        per_page: 10,
        page: 1,
        search: null,
        sort_by: 'id',
        sort_desc: false,
        keywords: [],
        opt_out: '',
    },
    total: 0,
    useStaticCoupon: false,
    useVoteCoupon: false,
}

export default {
    namespaced: true,

    state: Vue.util.extend({}, initState),

    mutations: {
        getAllSuccess(state, {
            keywords,
            queries,
            total
        }) {
            state.keywords = keywords
            state.queries = queries
            state.total = total
        },
        setStaticCoupon(state, value) {
            state.useStaticCoupon = value
        },
        setFeatures(state, data) {
            state.useStaticCoupon = data.static_coupon
            state.useVoteCoupon = data.vote_coupon
        },
    },

    actions: {

        getAll({
            commit
        }, queries) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/keywords', {
                        params: queries
                    })
                    .then((res) => {
                        commit('getAllSuccess', {
                            keywords: res.data.data,
                            queries: queries,
                            total: res.data.meta.total,
                        })
                        resolve(true)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        create({
            commit
        }, keyword) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/keywords`, keyword)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        update({
            commit
        }, keyword) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/keywords/${keyword.id}`, keyword)
                    .then((res) => {
                        resolve(true)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        destroy({ commit }, keywordId) {
            return new Promise((resolve, reject) => {
              axios
                .delete(`/keywords/${keywordId}`)
                .then((res) => {
                  resolve(res)
                })
                .catch((error) => {
                  reject(error)
                })
            })
        },

        hasStaticCoupon({ commit }, parmas) {
            return new Promise((resolve, reject) => {
              axios
                .post(`keywords/has-static-coupon`, parmas)
                .then((res) => {
                    commit('setStaticCoupon', res.data.data)
                })
                .catch((err) => reject(err))
            })
        },
        
        hasFeatures({ commit }, parmas) {
            return new Promise((resolve, reject) => {
              axios
                .post(`keywords/has-features`, parmas)
                .then((res) => {
                    commit('setFeatures', res.data.data)
                })
                .catch((err) => reject(err))
            })
        },
    },

    getters: {
        all(state) {
            return state.keywords
        },

        total(state) {
            return state.total
        },

        queries(state) {
            return state.queries
        },

        useStaticCoupon(state) {
            return state.useStaticCoupon
        },
        
        useVoteCoupon(state) {
            return state.useVoteCoupon
        },
    },
}