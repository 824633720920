import Vue from 'vue'
import axios from 'axios'

const initState = {
  whitelabels: null,
  total: 0,
  queries: {
    per_page: 10,
    page: 1,
    search: null,
    sort_by: 'domain',
    sort_desc: false,
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { whitelabels, queries, total }) {
      state.whitelabels = whitelabels
      state.total = total
      state.queries = queries
    },

    requestCertSuccess(state, whitelabelId) {
      state.whitelabels = state.whitelabels.map((whitelabel) => {
        if (whitelabel.id === whitelabelId) {
          whitelabel.is_vhost_exists = true
          whitelabel.is_ssl_cert_exists = true
        }

        return whitelabel
      })
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/whitelabels', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              whitelabels: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    requestCert({ commit }, whitelabelId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/whitelabels/${whitelabelId}/cert`)
          .then((res) => {
            commit('requestCertSuccess', whitelabelId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    total(state) {
      return state.total
    },

    all(state) {
      return state.whitelabels
    },

    queries(state) {
      return state.queries
    },
  },
}
