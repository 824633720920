import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  campaigns: null,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  actions: {
    all({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/integrations`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    type({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/integrations/type/${id}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, integrationId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/integrations/${integrationId}`)
          .then((res) => {
            resolve(res.data.data.integration)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    allUser({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/integrations/user`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/integrations`, params)
          .then((res) => {
            resolve(res.data.data.integration)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getGotoWebinars({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`integrations/goto/webinars`, { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getFbPages({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`integrations/fb/pages`, { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getFbForms({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`integrations/fb/forms`, { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getStripeProducts({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`integrations/stripe/products`, { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getStripePrices({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`integrations/stripe/prices`, { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    selectOptions({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`integrations/user/select-options`, { params: queries })
          .then((res) => {
            resolve(res.data.data.options)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    
    
    update({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/integrations/${params.id}`, params)
          .then((res) => {
            resolve(res.data.data.integration)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    syncData({ commit }, integrationId) {
      return new Promise((resolve, reject) => {
        axios
        .post(`/integrations/${integrationId}/sync-data`)
          .then((res) => {
            resolve(res.data.data.integration)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    toggleStatus({ commit }, integrationId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/integrations/${integrationId}/toggle-status`)
          .then((res) => {
            resolve(res.data.data.integration)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    delete({ commit }, integrationId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/integrations/${integrationId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.campaigns
    },
  },
}
