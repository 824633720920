import Vue from 'vue'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

Vue.filter('nationalNumber', function (val) {
    if (!val) return "";
    if (!val.startsWith("+")) val = "+" + val;

    const phoneNumber = parsePhoneNumberFromString(val);
    if (phoneNumber)
        return phoneNumber.formatNational()
    return val
});