import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import AgencyGuard from './guards/agency-guard'

export default [
  {
    path: '/agency/',
    component: DashboardLayout,
    beforeEnter: AgencyGuard,
    redirect: { name: 'agency.businesses.index' },
    children: [
      {
        name: 'agency.dashboard',
        path: 'dashboard',
        component: lazyLoading('Agency/Dashboard/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.index',
        path: 'businesses',
        component: lazyLoading('Agency/Businesses/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.archived',
        path: 'businesses/archived',
        component: lazyLoading('Agency/Businesses/Archived'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.edit',
        path: 'businesses/:id/edit',
        component: lazyLoading('Agency/Businesses/Edit'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.create',
        path: 'businesses/create',
        component: lazyLoading('Agency/Businesses/Create'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.businesses.share',
        path: 'businesses/:id',
        component: lazyLoading('Agency/Businesses/Share'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.payment-methods.index',
        path: 'payment-methods',
        component: lazyLoading('Agency/PaymentMethods/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.payment-methods.create',
        path: 'payment-methods/create',
        component: lazyLoading('Agency/PaymentMethods/Create'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.payment-methods.edit',
        path: 'payment-methods/:id/edit',
        component: lazyLoading('Agency/PaymentMethods/Create'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.subscription',
        path: 'subscription',
        component: lazyLoading('Agency/Subscription'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.settings',
        path: 'settings',
        component: lazyLoading('Agency/Setting/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.notifications.index',
        path: 'notifications',
        component: lazyLoading('Common/Notification'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.setups.twilio',
        path: 'setups/twilio',
        component: lazyLoading('Agency/Setups/Twilio'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.training',
        path: 'training',
        component: lazyLoading('Agency/Training'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.twoway',
        path: 'two-way',
        component: lazyLoading('Agency/SubscriptionLivechat'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.loop',
        path: 'loop',
        component: lazyLoading('Agency/SubscriptionLoop'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.reports.index',
        path: 'reports',
        component: lazyLoading('Agency/Reports/Index'),
        beforeEnter: AgencyGuard,
      },
    ],
  },
]
