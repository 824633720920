import store from '@store/index'

function BusinessGuard(to, from, next) {
  let user = store.getters['auth/user']

  if (!user) {
    const query = to.query;
    if (to.path) {
      query.path = to.path
    }
    next({ name: 'login', query })
  } else if (user.role !== 'Business') {
    next('/')
  } else if (!user.business.is_template && !user.business.agency && !user.subscribed && !user.business.free_fee) {
    if (to.name !== 'business.subscription') {
      next({ name: 'business.subscription' })
    } else {
      next()
    }
  } else if (!user.business.is_template && !user.business.phone_number) {
    if (!user.use_bandwidth) {
      if (to.name !== 'business.setup-phonenumber') {
        next({ name: 'business.setup-phonenumber' })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
}

export default BusinessGuard
