import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import BusinessGuard from './guards/business-guard'

export default [
  {
    path: '/business/',
    component: DashboardLayout,
    beforeEnter: BusinessGuard,
    redirect: { name: 'business.dashboard' },
    children: [
      {
        name: 'business.dashboard',
        path: 'dashboard',
        component: lazyLoading('Business/Dashboard'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.notifications.index',
        path: 'notifications',
        component: lazyLoading('Common/Notification'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.coupons.index',
        path: 'campaigns',
        component: lazyLoading('Business/Coupons/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.coupons.create',
        path: 'campaigns/create',
        component: lazyLoading('Business/Coupons/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.coupons.edit',
        path: 'campaigns/:id/edit',
        component: lazyLoading('Business/Coupons/Edit'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.coupons.setting',
        path: 'campaigns/:id/setting',
        component: lazyLoading('Business/Coupons/Setting'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.coupons.share',
        path: 'campaigns/:id',
        component: lazyLoading('Business/Coupons/Share'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings',
        path: 'settings',
        component: lazyLoading('Business/Settings/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.contacts.index',
        path: 'contacts',
        component: lazyLoading('Business/Contacts/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.contacts.create',
        path: 'contacts/create',
        component: lazyLoading('Business/Contacts/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.contacts.edit',
        path: 'contacts/:id/edit',
        component: lazyLoading('Business/Contacts/Edit'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.setup-phonenumber',
        path: 'setups/phone-number',
        component: lazyLoading('Business/Setups/PhoneNumber'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.broadcasts.index',
        path: 'broadcasts',
        component: lazyLoading('Business/Broadcasts/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.broadcasts.create',
        path: 'broadcasts/create',
        component: lazyLoading('Business/Broadcasts/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.broadcasts.duplicate',
        path: 'broadcasts/duplicate/:id',
        component: lazyLoading('Business/Broadcasts/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.broadcasts.resend',
        path: 'broadcasts/resend/:id',
        component: lazyLoading('Business/Broadcasts/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.broadcasts.edit',
        path: 'broadcasts/:id/edit',
        component: lazyLoading('Business/Broadcasts/Edit'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.broadcasts.report',
        path: 'broadcasts/:id/report',
        component: lazyLoading('Business/Broadcasts/Report'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.automations.index',
        path: 'automations',
        component: lazyLoading('Business/Automations/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.automations.create',
        path: 'automations/create',
        component: lazyLoading('Business/Automations/Automation'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.automations.edit',
        path: 'automations/:id/edit',
        component: lazyLoading('Business/Automations/Automation'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.automations.share',
        path: 'automations/:id',
        component: lazyLoading('Business/Automations/Share'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.landings.index',
        path: 'campaigns/landings',
        component: lazyLoading('Business/Landings/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.landings.create',
        path: 'campaigns/landings/create',
        component: lazyLoading('Business/Landings/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.loopStatistics',
        path: 'stats-loop',
        component: lazyLoading('Business/Loop/Statistics'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.landings.edit',
        path: 'campaigns/landings/:id/edit',
        component: lazyLoading('Business/Landings/Edit'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.popup.create',
        path: 'campaigns/popup/create',
        component: lazyLoading('Business/Popup/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.popup.edit',
        path: 'campaigns/popup/:id/edit',
        component: lazyLoading('Business/Popup/Edit'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.keyword',
        path: 'settings/keywords',
        component: lazyLoading('Business/Keywords/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.integrations',
        path: 'settings/integrations',
        component: lazyLoading('Business/Integrations/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.integrations.create',
        path: 'settings/integrations/create',
        component: lazyLoading('Business/Integrations/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.integrations.edit',
        path: 'settings/integrations/:id/edit',
        component: lazyLoading('Business/Integrations/Edit'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.livechat.index',
        path: 'livechat',
        component: lazyLoading('Business/Livechat/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.subscription',
        path: 'subscription',
        component: lazyLoading('Business/Subscription'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.payment-methods.index',
        path: 'payment-methods',
        component: lazyLoading('Business/PaymentMethods/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.payment-methods.create',
        path: 'payment-methods/create',
        component: lazyLoading('Business/PaymentMethods/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.payment-methods.edit',
        path: 'payment-methods/:id/edit',
        component: lazyLoading('Business/PaymentMethods/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.twoway',
        path: 'two-way',
        component: lazyLoading('Business/SubscriptionLivechat'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.resources',
        path: 'settings/resources',
        component: lazyLoading('Business/Resources'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.loop',
        path: 'loop',
        component: lazyLoading('Business/SubscriptionLoop'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.apikeys',
        path: 'settings/apikeys',
        component: lazyLoading('Business/ApiKeys/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.apikeys.create',
        path: 'settings/apikeys/create',
        component: lazyLoading('Business/ApiKeys/Create'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.apikeys.edit',
        path: 'settings/apikeys/:id/edit',
        component: lazyLoading('Business/ApiKeys/Edit'),
        beforeEnter: BusinessGuard,
      },
      {
        name: 'business.settings.customFields',
        path: 'settings/custom-fields',
        component: lazyLoading('Business/CustomFields/Index'),
        beforeEnter: BusinessGuard,
      },
    ],
  },
]
