import Vue from 'vue'
import axios from 'axios'

const initState = {
  data: null,
  queries: {
    per_page: 10,
    page: 1,
    search: null,
    sort_by: 'id',
    sort_desc: false,
  },
  total: 0,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, {
      data,
      queries,
      total
    }) {
      state.data = data
      state.queries = queries
      state.total = total
    },
  },

  actions: {
    getAll({
      commit
    }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/custom-fields', {
            params: queries
          })
          .then((res) => {
            commit('getAllSuccess', {
              data: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/custom-fields`, params)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({
      commit
    }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/custom-fields/${params.id}`, params)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    destroy({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/custom-fields/${id}`)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.data
    },

    total(state) {
      return state.total
    },

    queries(state) {
      return state.queries
    },
  },
}