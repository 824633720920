<template>
  <div id="app" :class="{ 'has-notification': notificationRequested }">
    <div class="top-notification-bar notify text-white app-notification">We need your permission to <span
        class="cursor-pointer pl-1" @click="enableNotification"><u>enable desktop notifications</u></span>.</div>
    <router-view></router-view>
    <div v-if="loading" class="loading-container">
      <b-spinner variant="primary" style="width: 80px; height: 80px" />
    </div>
    <b-modal v-model="showCreditAlertModal" no-close-on-backdrop hide-header-close>
      <template v-slot:modal-title>
        <h5 class="text-danger">Payment Failed</h5>
      </template>
      <p>
        Your business accounts have been disabled and no text messages are currently being delivered. Please update your
        credit card.
      </p>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="onCreditClick">Verify Credit Card</b-button>
      </template>
    </b-modal>
    <b-modal v-model="isShowA2pConfirmationModal" title="SMS Registration Alert">
      <p>Your SMS registration is currently not active and you won't be able to send SMS messages until it is
        activated.<br /><br /> Please contact the <a :href="'mailto:' + agencyEmail" target="_blank"><b
            class="text-primary">admin</b></a> to activate your SMS
        Registration.</p>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="isShowA2pConfirmationModal = false">Got It</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Pusher from 'pusher-js'
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import moment from 'moment-timezone'

export default {
  data() {
    return {
      notificationPermiossion: undefined,
      showCreditAlertModal: false,
      isShowA2pConfirmationModal: false,
    }
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),

    config() {
      return this.$store.getters['app/config']
    },

    loading() {
      return this.$store.getters['app/loading']
    },

    selectedContact() {
      return this.$store.getters['livechat/selectedContact']
    },

    isBusiness() {
      return this.user && this.user.role === 'Business';
    },

    notificationRequested() {
      return this.isBusiness && !this.isNumberVerificationRequired && !this.showA2pSubscriptionAlert && this.notificationPermiossion === 'default'
    },

    notificationGranted() {
      return this.notificationPermiossion === 'granted'
    },

    isNumberVerificationRequired() {
      let statusList = ['completed', 'in-progress', 'pending', 'verified', 'resolved - verified']
      return this.isBusiness && this.isBandwidthUser &&
        (!statusList.includes(this.user.business.number_status) || !this.business.phone_number)
    },

    showA2pSubscriptionAlert() {
      let agency = this.business.agency
      return this.isBusiness && !this.business.is_tollfree && this.isBandwidthUser && this.user.a2p_subscription_status && !['active', 'trialing'].includes(this.user.a2p_subscription_status) && !this.terminateAt &&
        (!this.business.free_fee && (!agency || !agency.free_fee)) && !this.isNumberVerificationRequired
    },

    business() {
      return this.user && this.user.business
    },

    isBandwidthUser() {
      return this.user && this.user.use_bandwidth
    },

    agencyEmail() {
      return this.business && this.business.agency && this.business.agency.email;
    },

    terminateAt() {
      let res = null
      if (this.user && this.user.a2p_subscription_end_at > moment().subtract(7, 'days').format('YYYY-MM-DD')) {
        res = moment(this.user.a2p_subscription_end_at).add(7, 'days').format('MMM Do, h:mm A');
      }
      return res
    },
  },

  watch: {
    config(val) {
      if (val) {
        let faviconEl =
          document.querySelector("link[rel*='icon']") ||
          document.createElement('link')
        faviconEl.rel = 'icon'
        faviconEl.type = 'image/x-icon'
        faviconEl.href = val.favicon
        document.getElementsByTagName('head')[0].appendChild(faviconEl)

        let titleEl =
          document.querySelector('title') || document.createElement('title')
        titleEl.text = val.name
      }
    },
    user: function (newVal, oldVal) {
      if (newVal) {
        this.$store.dispatch('user/getNotifications')
        if (this.isBusiness && this.notificationGranted) {
          this.registerPushNotification()
        }

        if (newVal.business && newVal.business.is_active_livechat) {
          this.initPusher()
        }

        if (newVal.role === 'Agency' && newVal.credit_status === 2 && newVal.stripe_status === "active") {
          this.showCreditAlert()
        }
      }
    },
    $route(to, from) {
      if (to && !['landing', 'loops', 'thanks', 'result'].includes(to.name)) {
        if (this.user && this.user.role === 'Agency' && process.env.NODE_ENV === 'production') {
          this.install_support_script()
        } else {
          this.destroy_support_script()
        }

        if (this.isBusiness && this.isBandwidthUser && ['business.broadcasts.create', 'business.livechat.index'].includes(to.name)) {
          if (to.name !== 'business.broadcasts.create' || to.query.type !== 'email') {
            if (this.showA2pSubscriptionAlert) {
              this.showA2pConfirmationModal();
            }
          }
        }
      }
    },
  },

  created() {
    window.fwSettings = {
      widget_id: 66000000343,
    }
    this.notificationPermiossion = Notification && Notification.permission
  },


  methods: {
    showA2pConfirmationModal() {
      let storageKey = 'lastShowA2pConfirmationAt_' + this.user.id
      let lastShowA2pAt = localStorage.getItem(storageKey);
      if (!lastShowA2pAt) {
        lastShowA2pAt = moment().subtract(1, 'days').format('MM-DD')
      }

      if (lastShowA2pAt < moment().format('MM-DD') && (!this.business.agency || !this.business.agency.is_template)) {
        localStorage.setItem(storageKey, moment().format('MM-DD'))
        this.isShowA2pConfirmationModal = true
        this.$store.dispatch('user/sendA2pNotification');
      }
    },

    showCreditAlert() {
      this.showCreditAlertModal = true
    },

    onCreditClick() {
      this.showCreditAlertModal = false
      this.$router.push({ name: 'agency.payment-methods.index' })
    },

    enableNotification() {
      Notification.requestPermission().then((result) => {
        this.notificationPermiossion = result
        if (result === 'granted') {
          this.registerPushNotification();
        }
      });
    },

    initPusher() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      })

      let channel = pusher.subscribe(`${process.env.VUE_APP_NOTIFICATION_CHANNEL}${this.user.id}`)
      channel.bind('new-message', ({ message, contact, data }) => {
        if (!this.selectedContact || contact.id !== this.selectedContact.id) {
          this.$store.dispatch('user/updateUnread', data.unread)
        }
      })
    },

    install_support_script() {
      if (!window.FreshworksWidget) {
        /* eslint-disable no-eval */
        eval(
          `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://widget.freshworks.com/widgets/66000000343.js",e.parentNode.insertBefore(n,e)}if(e.FreshworksWidget=n=function(t,n,a){e.FreshworksWidget.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.FreshworksWidget||function(){});window.FreshworksWidget('boot')`
        )
      }
    },

    destroy_support_script() {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('destroy')
        window.FreshworksWidget = undefined
      }
    },

    registerPushNotification() {
      if (!this.user || this.user && this.user.role !== 'Business') return;

      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: `${process.env.VUE_APP_BASE_API}api/v1/pusher/beams-auth`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem('access_token'),
          'Content-Type': 'application/json'
        },
      })

      const beamsClient = new PusherPushNotifications.Client({
        instanceId: process.env.VUE_APP_PUSHER_INSTANCE_ID,
      })

      beamsClient
        .start()
        .then(() => beamsClient.setUserId(`user-${this.user.id}`, beamsTokenProvider))
        .catch(console.error)

      beamsClient
        .getUserId()
        .then((userId) => {
          if (userId !== `user-${this.user.id}`) {
            return beamsClient.stop();
          }
        })
        .catch(console.error)
    },
  },
}
</script>

<style lang="scss">
@import '@design';

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
