import Vue from 'vue'
import Router from 'vue-router'
import store from '@store/index'

import commonRoutes from './common-routes'
import authRoutes from './auth-routes'
import adminRoutes from './admin-routes'
import agencyRoutes from './agency-routes'
import businessRoutes from './business-routes'

Vue.use(Router)

const createRouter = () => {
  const routes = [
    ...commonRoutes,
    ...authRoutes,
    ...adminRoutes,
    ...agencyRoutes,
    ...businessRoutes,
  ]

  const router = new Router({
    mode: 'history',
    routes: routes,
  })

  return router
}

const router = createRouter()

Router.prototype.resetRouter = function() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}

const commonRouteNames = commonRoutes.map(item => item.name)

router.beforeEach((to, from, next) => {
  if (!store.getters['app/config'])
  {
    if (!commonRouteNames.includes(to.name))
    {
      store.dispatch('app/getConfig', location.hostname)
    }
  }
  if (store.getters['auth/isLoggedIn'] && !store.state.auth.user) {
    if (commonRouteNames.includes(to.name))
    {
      next()
    } else {
      store
        .dispatch('auth/getProfile')
        .then((res) => {
          next()
        })
        .catch(() => {
          next()
        })
    }
  } else {
    next()
  }
})

export default router
