import Vue from 'vue'
import store from '../store/index'

Vue.filter('legal', function (val, companyName) {
  if (!companyName) {
    let business = store.getters['auth/business']
    companyName = business ? business.name : ''
  }
  if (val) {
    return val.replace('[company name]', companyName)
  }
  return ''
})