import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  mutations: {},

  actions: {
    getA2pList({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/a2p`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getA2pRequest({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/a2p/${id}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateA2pRequest({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/a2p/${params.id}`, params)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getTollfreeVerificationList({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/tollfree`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getTollfreeRequest({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/tollfree/${id}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateTollfreeRequest({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/tollfree/${params.id}`, params)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {},
}
