import axios from 'axios'

export default {
  namespaced: true,

  state: {},

  mutations: {
  },

  actions: {
    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/whitelists', { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },


    create({ commit }, whitelist) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/whitelists`, whitelist)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, whitelist) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/whitelists/${whitelist.id}`, whitelist)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    remove({ commit }, whitelistId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/whitelists/${whitelistId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.campaigns
    },
  },
}
