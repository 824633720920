import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  contacts: null,
  queries: {
    per_page: 10,
    page: 1,
    search: null,
    sort_by: 'id',
    sort_desc: false,
    keywords: [],
    opt_out: '',
  },
  total: 0,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { contacts, queries, total }) {
      state.contacts = contacts
      state.queries = queries
      state.total = total
    },

    createSuccess(state, redemption) {
      this.state.auth.user.business.usage_contacts += 1
      if (state.contacts) {
        state.contacts = [...state.contacts, redemption]
      }
    },

    updateSuccess(state, redemption) {
      if (state.contacts) {
        state.contacts = state.contacts.map((obj) =>
          obj.id === redemption.id ? redemption : obj
        )
      }
    },

    removeSuccess(state, redemptionId) {
      this.state.auth.user.business.usage_contacts -= 1
      let index = state.contacts.findIndex((c) => c.id === redemptionId)

      state.contacts.splice(index, 1)
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    all({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contacts/all')
          .then((res) => {
            resolve(res.data.data.contacts)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAllUploads({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contacts/uploads', { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/contacts', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              contacts: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getLeaderBoard({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/leaderboard', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              contacts: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, redemptionId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/contacts/${redemptionId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    startAutomation({ commit }, { contactId, automationId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/contacts/${contactId}/start-automation`, {
            automationId: automationId,
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    bulkStartAutomation({ commit }, { contactIds, automationId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/contacts/bulk-start-automation`, {
            automationId: automationId,
            contactIds: contactIds,
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    stopAutomation({ commit }, { contactId, automationId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/contacts/${contactId}/stop-automation`, {
            automationId: automationId,
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, contact) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/contacts`, contact)
          .then((res) => {
            commit('createSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, contact) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/contacts/${contact.id}`, contact)
          .then((res) => {
            commit('updateSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    remove({ commit }, redemptionId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/contacts/${redemptionId}`)
          .then((res) => {
            commit('removeSuccess', redemptionId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    bulkRemove({ commit }, { contactIds, filters }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/contacts/bulk-remove`, { data: { contact_ids: contactIds, filters: filters, }})
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    bulkAssignKeywords({ commit }, { keywords, contactIds, filters }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/contacts/bulk-assign-keywords`, { contact_ids: contactIds, keywords: keywords, filters: filters, })
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    toggleOptOut({ commit }, param) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/contacts/${param.id}/opt-out`, param)
          .then((res) => {
            commit('updateSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateRedemption({ commit }, redemption) {
      return new Promise((resolve, reject) => {
        axios
          .put(`redemptions/${redemption.id}`, redemption)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    removeRedemption({ commit }, redemptionId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`redemptions/${redemptionId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    syncKeywords({ commit }, contact) {
      return new Promise((resolve, reject) => {
        axios
          .put(`contacts/${contact.id}/sync-keywords`, { keywords: contact.keywords })
          .then((res) => {
            resolve(res.data.data.contact)
          })
          .catch((err) => reject(err))
      })
    },

    importContacts({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/contacts/import`, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.contacts
    },

    total(state) {
      return state.total
    },

    queries(state) {
      return state.queries
    },
  },
}
