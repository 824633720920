import Vue from 'vue'
import Vuex from 'vuex'

import automationModule from './modules/automation-module'
import appModule from './modules/app-module'
import authModule from './modules/auth-module'
import broadcastModule from './modules/broadcast-module'
import userModule from './modules/user-module'
import subscriptionModule from './modules/subscription-module'
import paymentMethodModule from './modules/payment-method-module'
import agencyModule from './modules/agency-module'
import businessModule from './modules/business-module'
import whitelabelModule from './modules/whitelabel-module'
import couponModule from './modules/coupon-module'
import campaignModule from './modules/campaign-module'
import contactModule from './modules/contact-module'
import reportModule from './modules/report-module'
import landingModule from './modules/landing-module'
import keywordModule from './modules/keyword-module'
import livechatModule from './modules/livechat-module'
import loopModule from './modules/loop-module'
import whitelistModule from './modules/whitelist-module'
import blacklistModule from './modules/blacklist-module'
import uploadModule from './modules/upload-module'
import integrationModule from './modules/integration-module'
import apikeyModule from './modules/apikey-module'
import adminModule from './modules/admin-module'
import customFieldModule from './modules/customfield-module'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,
  modules: {
    app: appModule,
    automation: automationModule,
    auth: authModule,
    broadcast: broadcastModule,
    user: userModule,
    paymentMethod: paymentMethodModule,
    subscription: subscriptionModule,
    agency: agencyModule,
    business: businessModule,
    whitelabel: whitelabelModule,
    coupon: couponModule,
    campaign: campaignModule,
    contact: contactModule,
    report: reportModule,
    landing: landingModule,
    keyword: keywordModule,
    livechat: livechatModule,
    loop: loopModule,
    whitelist: whitelistModule,
    blacklist: blacklistModule,
    upload: uploadModule,
    integration: integrationModule,
    apikey: apikeyModule,
    admin: adminModule,
    customField: customFieldModule,
  },
})

export default store
