var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left-side-menu"},[_c('div',{staticClass:"sidebar-content"},[_c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu",attrs:{"id":"side-menu"}},_vm._l((_vm.renderMenuItems),function(menuItem,index){return _c('li',{key:index,staticClass:"side-nav-title side-nav-item",class:{ 
            'mm-active': _vm.currentRoute.match(menuItem.pathActive),
            'two-way': menuItem.route === 'agency.twoway',
          }},[(!menuItem.items && menuItem.route)?_c('router-link',{staticClass:"side-nav-link side-nav-link-ref",attrs:{"to":{ name: menuItem.route },"exact-active-class":".side-nav-item"}},[_c('feather',{attrs:{"type":menuItem.icon}}),_c('span',[_vm._v(_vm._s(menuItem.label))]),(menuItem.label === 'Message' && _vm.user.business && _vm.user.business.unread > 0)?_c('span',{staticClass:"count-message"},[_vm._v(" "+_vm._s(_vm.user.business.unread)+" ")]):_vm._e()],1):_vm._e(),(!menuItem.items && menuItem.routeOut)?_c('a',{staticClass:"side-nav-link",attrs:{"href":menuItem.routeOut,"target":"_blank"}},[_c('feather',{attrs:{"type":menuItem.icon}}),_c('span',[_vm._v(_vm._s(menuItem.label))])],1):_vm._e(),(menuItem.items)?_c('router-link',{staticClass:"side-nav-link",attrs:{"to":{ name: menuItem.route },"aria-expanded":_vm.currentRoute.match(menuItem.pathActive) !== null},on:{"click":function($event){return _vm.clickParentItem(menuItem)}}},[_c('feather',{attrs:{"type":menuItem.icon}}),_c('span',[_vm._v(_vm._s(menuItem.label))]),_c('span',{staticClass:"menu-arrow"})],1):_vm._e(),(menuItem.items)?_c('ul',{staticClass:"nav-second-level mm-collapse",class:{ 'mm-show': _vm.currentRoute.match(menuItem.pathActive) }},_vm._l((menuItem.items),function(item,index1){return _c('li',{key:index1},[_c('router-link',{attrs:{"to":{ name: item.route }}},[_vm._v(_vm._s(item.label))])],1)}),0):_vm._e()],1)}),0)]),(_vm.showNotification)?_c('div',{staticStyle:{"flex":"0","padding-bottom":"0"},attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu side-menu-notification",staticStyle:{"margin":"0"},attrs:{"id":"side-menu"}},[_c('li',{staticClass:"side-nav-title side-nav-item",class:{
            'mm-active': _vm.currentRoute.match(
              ((_vm.userRole.toLocaleLowerCase()) + ".notifications.index")
            ),
          }},[_c('a',{staticClass:"side-nav-link side-nav-link-ref",attrs:{"href":"javascipt:;","exact-active-class":".side-nav-item"}},[_c('feather',{attrs:{"type":"bell"}}),_c('span',[_vm._v("Notification")])],1)])])]):_vm._e(),(_vm.user)?_c('div',{staticClass:"media user-profile mt-2 mb-2"},[_c('img',{staticClass:"avatar-sm rounded-circle mr-2",attrs:{"src":_vm.user.avatar}}),_c('img',{staticClass:"avatar-xs rounded-circle mr-2",attrs:{"src":_vm.user.avatar}}),_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"pro-user-name mt-0 mb-0"},[_vm._v(_vm._s(_vm.user.full_name))]),_c('span',{staticClass:"pro-user-desc"},[_vm._v(_vm._s(_vm.user.role))])]),_c('b-dropdown',{staticClass:"align-self-center",attrs:{"variant":"black","toggle-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather',{staticClass:"align-middle",attrs:{"type":"chevron-down"}})]},proxy:true}],null,false,2129541470)},[(_vm.isImpersonating)?_c('b-dropdown-item',{on:{"click":_vm.stopImpersonate}},[_c('feather',{staticClass:"icon-dual icon-xs mr-2",attrs:{"type":"log-in"}}),_c('span',[_vm._v("Main account")])],1):_vm._e(),(_vm.user && _vm.userRole !== 'Business')?_c('b-dropdown-item',{attrs:{"to":{ name: ((_vm.userRole.toLocaleLowerCase()) + ".profile") }}},[_c('feather',{staticClass:"icon-dual icon-xs mr-2",attrs:{"type":"user"}}),_c('span',[_vm._v("My Account")])],1):_vm._e(),_c('b-dropdown-divider'),_c('b-dropdown-item',{staticClass:"notify-item p-0",on:{"click":function($event){$event.preventDefault();return _vm.logout($event)}}},[_c('feather',{staticClass:"icon-dual icon-xs mr-2",attrs:{"type":"log-out"}}),_c('span',[_vm._v("Logout")])],1)],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }