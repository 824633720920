import Vue from 'vue'
import axios from 'axios'

const initState = {
  plans: null,
  businessPlans: null,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, plans) {
      state.plans = plans.sort((a, b) => {
        return a.amount - b.amount
      })
    },

    getAllBusinessPlansSuccess(state, plans) {
      state.businessPlans = plans.sort((a, b) => {
        return a.amount - b.amount
      })
    },

    subscribeSuccess(state, plan) {
      this.state.auth.user = {
        ...this.state.auth.user,
        stripe_plan: plan,
        subscribed: true,
      }
    },

    createPlanSuccess(state, plan) {
      if (state.plans) {
        state.plans.push(plan)
      }
    },

    createBusinessPlanSuccess(state, plan) {
      if (state.businessPlans) {
        state.businessPlans.push(plan)
      }
    },

    updatePlanSuccess(state, plan) {
      if (state.plans) {
        state.plans = state.plans.map((p) => (p.id === plan.id ? plan : p))
      }
    },

    updateBusinessPlanSuccess(state, plan) {
      if (state.businessPlans) {
        state.businessPlans = state.businessPlans.map((p) => (p.id === plan.id ? plan : p))
      }
    },

    deletePlanSuccess(state, planId) {
      let planIndex = state.plans.findIndex((plan) => plan.id === planId)

      state.plans.splice(planIndex, 1)
    },

    resumeSuccess(state, user) {
      this.state.auth.user = user
    },

    chargeBalanceSuccess(state, user) {
      this.state.auth.user = user
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    getAllPrices({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`subscriptions/prices`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {})
      })
    },

    getAllPlans({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`subscriptions/plans`)
          .then((res) => {
            commit('getAllSuccess', res.data.data)
            resolve(true)
          })
          .catch(() => {})
      })
    },

    getAllBusinessPlans({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`business-plans`)
          .then((res) => {
            commit('getAllBusinessPlansSuccess', res.data.data)
            resolve(true)
          })
          .catch(() => {})
      })
    },

    findPlan({ commit }, planId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`plans/${planId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {})
      })
    },

    findBusinessPlan({ commit }, planId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`business-plans/${planId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(() => {})
      })
    },

    createPlan({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .post(`plans`, plan)
          .then((res) => {
            commit('createPlanSuccess', res.data.data)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    createBusinessPlan({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .post(`business-plans`, plan)
          .then((res) => {
            commit('createBusinessPlanSuccess', res.data.data)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updatePlan({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .put(`plans/${plan.id}`, plan)
          .then((res) => {
            commit('updatePlanSuccess', res.data.data)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updateBusinessPlan({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .put(`business-plans/${plan.id}`, plan)
          .then((res) => {
            commit('updateBusinessPlanSuccess', res.data.data)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    deletePlan({ commit }, planId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`plans/${planId}`)
          .then((res) => {
            commit('deletePlanSuccess', planId)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getPlanByName({ commit }, name) {
      return new Promise((resolve, reject) => {
        axios
          .get(`subscriptions/plans/${name}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    subscribe({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/${plan}`)
          .then((res) => {
            commit('subscribeSuccess', plan)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    chargeBalance({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/add-fund`, params)
          .then((res) => {
            commit('chargeBalanceSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    subscribeUnlimited({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/subscribe-unlimited`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    cancel({ commit }, { userId, section }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`subscriptions/${userId}/cancel?section=${section}`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    resume({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/resume`)
          .then((res) => {
            commit('resumeSuccess', res.data.data.user)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getCheckoutLink({ commit }, plan) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/${plan}/checkout`)
          .then((res) => {
            resolve(res.data.data.link)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    subscribeA2p({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`subscriptions/a2p`, params)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },

  getters: {
    allPlans(state) {
      return state.plans
    },

    allBusinessPlans(state) {
      return state.businessPlans
    }
  },
}
