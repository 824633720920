<template>
  <div class="navbar navbar-expand flex-column flex-md-row navbar-custom">
    <div class="container-fluid">
      <router-link
        v-if="config"
        class="navbar-brand mr-0 mr-md-2 logo"
        :to="'/' + userRole.toLocaleLowerCase()"
      >
        <span class="logo-lg">
          <img v-if="config.logo" :src="config.logo" alt height="48" />
          <span v-else class="d-inline h5 ml-2 text-logo">{{
            config.name
          }}</span>
        </span>
        <span class="logo-sm">
          <img :src="config.favicon" alt height="36" />
        </span>
      </router-link>
      <ul
        class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0 mr-auto"
      >
        <li>
          <button
            class="button-menu-mobile open-left disable-btn"
            :class="{ open: isMenuOpened }"
            @click="toggleMenu"
          >
            <feather type="menu" class="menu-icon align-middle"></feather>
            <feather type="x" class="close-icon"></feather>
          </button>
        </li>
        <li v-if="user && user.role === 'Business'" class="d-flex flex-column align-items-start justify-content-center">
          <div class="font-weight-bold font-size-18"><span>Marketing Number</span></div>
          <div class="font-weight-bold text-muted"> {{ businessPhone }}</div>
        </li>
        <li v-if="user && user.role === 'Agency' && hasFreeValues" class="d-flex flex-column align-items-start justify-content-center">
          <div class="font-weight-bold agency-metrics text-success d-flex">
            <div :class="{ 'text-primary': user.agency.usage_numbers > user.agency.free_numbers }"><span>{{ `${user.agency.usage_numbers}/${user.agency.free_numbers}` }}</span> Numbers</div>
            <div :class="{ 'text-primary': user.agency.usage_messages > user.agency.free_messages }"><span>{{ `${user.agency.usage_messages}/${user.agency.free_messages}` }}</span> Messages</div>
            <div :class="{ 'text-primary': user.agency.usage_calls > user.agency.free_calls }"><span>{{ `${user.agency.usage_calls}/${user.agency.free_calls}` }}</span> Minutes</div>
          </div>
        </li>
      </ul>
      <ul
        class="navbar-nav flex-row ml-auto d-flex list-unstyled topnav-menu float-right mb-0"
      >
        <b-nav-item-dropdown
          v-if="user && user.role === 'Business'"
          id="bell-notification"
          right
          variant="white"
          class="notification-list"
          menu-class="dropdown-lg"
          :title="`${totalUnreadNotifications} new unread notifications`"
        >
          <template v-slot:button-content>
            <feather type="bell" class="align-middle"></feather>
            <span v-if="totalUnreadNotifications" class="noti-icon-badge"></span>
          </template>
          <b-tooltip target="bell-notification" placement="left"
            >{{ `${totalUnreadNotifications} new unread notifications` }}</b-tooltip
          >
          <b-dropdown-text class="noti-title border-bottom pb-2" tag="div">
            <h5 class="m-0 font-size-16">
              Notification
            </h5>
          </b-dropdown-text>
          <p v-if="!notifications.length" class="text-center pt-3">
            You don't have any notifications
          </p>

          <VuePerfectScrollbar v-else v-once class="noti-scroll">
            <b-dropdown-text
              v-for="(notice, index) in notifications"
              :key="index"
              href="javascript:void(0);"
              class="notify-item border-bottom"
            >
              <div class="notify-icon">
                <img
                  v-if="config.favicon"
                  :src="config.favicon"
                  class="img-fluid rounded-circle"/>
                <img
                  v-else
                  src="@assets/images/users/avatar-1.jpg"
                  class="img-fluid rounded-circle"/>
              </div>
              <p class="notify-details">{{ notice.title }}</p>
              <p class="text-muted mb-0 user-msg">
                <small>{{ notice.content | strlimit(80) }}</small>
              </p>
            </b-dropdown-text>
          </VuePerfectScrollbar>
          <div class="d-flex">
            <router-link
            v-if="user && notifications.length"
            class="dropdown-item text-center text-primary notify-item notify-all border-top"
            :to="{ name: `${user.role.toLocaleLowerCase()}.notifications.index` }">
            View all
            <i class="fi-arrow-right"></i>
            </router-link>
            <a
              v-if="notifications.length"
              href="javascript:void(0)"
              class="dropdown-item text-center text-primary notify-item notify-all border-top"
              @click="markAllAsRead">
              Mark all as read
              <i class="fi-arrow-right"></i>
            </a>
          </div>
        </b-nav-item-dropdown>
      </ul>
      <div v-if="user && user.role === 'Business'" class="pull-right">
        <a class="btn btn-primary" target="_blank" :href="getRedemptionPageUrl">
          Go to Redemption page
        </a>
      </div>
      <div v-if="user && user.role === 'Admin'" class="pull-right">
        <router-link
          :to="{ name: 'admin.businesses.create' }"
          class="btn btn-primary mr-2"
        >
          <i class="uil uil-plus"></i> New Business
        </router-link>

        <router-link
          :to="{ name: 'admin.agencies.create' }"
          class="btn btn-primary"
        >
          <i class="uil uil-plus"></i> New Agency
        </router-link>
      </div>
      <div v-if="user && user.role === 'Agency'" class="pull-right">
        <button v-if="!user.agency.has_sample" class="btn btn-primary mr-2" :disabled="loadingAddSample"
          @click="handleAddSampleAccount">
          <b-spinner v-if="loadingAddSample" small /> <i v-else class="uil uil-plus"></i> Demo Account
        </button>
        <a href="javascript:;" class="btn btn-primary" @click="onNewBusiness"><i class="uil uil-plus"></i> New Business</a>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
  },

  data() {
    return {
      loadingStopImpersonate: false,
      isMenuOpened: false,
      loadingMark: false,
      loadingAddSample: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    balance() {
      return this.user && this.user.balance ? this.user.balance : 0;
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    config() {
      return this.$store.getters['app/config']
    },

    notifications() {
      return this.$store.getters['user/notifications'] || []
    },

    totalUnreadNotifications() {
      return this.notifications.length
    },

    getRedemptionPageUrl() {
      if (!this.config) return null
      const  { whitelabel } = this.config
      if (this.config.custom_domain && whitelabel && whitelabel.is_ssl_cert_exists && whitelabel.is_vhost_exists) {
        return `https://${this.config.custom_domain}/${
          this.user.business.unique_id
        }/redemption`
      } else {
        return `https://${this.config.subdomain}.${
          this.config.whitelabel.domain
        }/${this.user.business.unique_id}/redemption`
      }
    },
    
    businessPhone() {
      let result = '';
      if (this.user && this.user.role === 'Business')
      {
        result = this.formatNational(this.user.business.phone_number)
      }
      return result
    },

    hasFreeValues() {
      return this.user && this.user.agency && (this.user.agency.free_messages || this.user.agency.free_calls || this.user.agency.free_numbers)
    },
  },

  mounted() {
    document.body.classList.toggle('left-side-menu-condensed')
  },

  methods: { 
    onNewBusiness() {
      this.$router.push({ name: 'agency.businesses.create' })
    },
    toggleMenu() {
      document.body.classList.toggle('left-side-menu-condensed')
      document.body.classList.toggle('sidebar-enable')
      this.$emit('toggleMenu', true)
    },

    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + this.userRole.toLocaleLowerCase())
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },

    markAllAsRead() {
      this.loadingMark = true
      this.$store
        .dispatch('user/markAllNotificationAsRead')
        .then(() => {
          this.loadingMark = false
        })
        .catch(() => {
          this.loadingMark = false
        })
    },

    handleAddSampleAccount() {
      this.loadingAddSample = true

      this.$store
        .dispatch('agency/addSampleAccount')
        .then(() => {
          this.$store.dispatch('business/getAll')
          this.loadingAddSample = false
        })
        .catch(() => {
          this.loadingAddSample = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .agency-metrics {
    font-size: 15px;
    >div {
      padding-right: 15px;
      span {
        font-size: 18px;
      }
    }
  }
</style>

<style lang="scss">
.noti-scroll {
  height: 220px;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.button-menu-mobile {
  outline: none !important;
}
</style>
