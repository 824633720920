import store from '@store/index'

function AgencyGuard(to, from, next) {
  let user = store.getters['auth/user']

  if (!user) {
    const query = to.query;
    if (to.path) {
      query.path = to.path
    }
    next({ name: 'login', query })
  } else if (user.role !== 'Agency') {
    next('/')
  } else if (!user.subscribed && !user.agency.free_fee && !user.agency.is_template) {
    if (to.name !== 'agency.subscription') {
      next({ name: 'agency.subscription' })
    } else {
      next()
    }
  } else if (!user.agency.is_template 
    && (!user.agency.twilioConfig || user.agency.twilioConfig && !user.agency.twilioConfig.token)
  ) {
    // if (to.name !== 'agency.setups.twilio') {
    //   next({ name: 'agency.setups.twilio' })
    // } else {
    //   next()
    // }
    next()
  } else {
    next()
  }
}

export default AgencyGuard
