import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  mutations: {},

  actions: {
    dashboard({ commit }, dateRange) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/businesses/dashboard-reports`, {
            params: { date_range: dateRange },
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    adminDashboard({ commit }, dateRange) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/dashboard-reports`, {
            params: { date_range: dateRange },
          })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    adminBusinessReport({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/business-reports`, {
            params,
          })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAllMdr({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/mdr-list`, { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAllBdr({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/bdr-list`, { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAllSms({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/sms-list`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAllCalls({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/call-list`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getBillingHistory({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/billing-history`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },

  getters: {},
}
