<template>
  <div id="wrapper" :class="{ 'has-notification': hasNotification }">
    <!-- eslint-disable vue/no-v-html -->
    <div class="top-notification-bar top-notification-bar-inside" :class="{
      'danger text-white': notificationData['status'] === 'danger',
      'warning': notificationData['status'] === 'warning',
      'primary text-white': notificationData['status'] === 'primary',
    }" @click="handleNotificationClick" v-html="notificationData['msg']"></div>
    <!--eslint-enable-->
    <Topbar v-show="!blockedScreen" :is-menu-opened="showMobileMenu" />
    <SideBar v-show="!blockedScreen" v-if="user" />
    <div class="content-page" :class="{ 'blocked-screen': blockedScreen }">
      <div class="content" style="margin-top:25px;">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
    </div>

    <b-modal v-model="showWarningTrial" title="Notification">
      <p>Twilio Free Trial account doesn't work with our system. Please upgrade your Twilio account to Paid one to move
        forward.</p>
      <template v-slot:modal-footer>
        <b-button variant="warning" @click="showWarningTrial = false">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Topbar from './Partials/Topbar'
import SideBar from './Partials/SideBar'
import moment from 'moment-timezone'

export default {
  components: { Topbar, SideBar },

  data() {
    return {
      showMobileMenu: false,
      showWarningTrial: false,
    }
  },

  computed: {
    blockedScreen() {
      return this.$store.getters['auth/blockedScreen']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    isBusiness() {
      return this.user && this.user.role === 'Business';
    },

    business() {
      return this.user && this.user.business
    },

    isBandwidthUser() {
      return this.user && this.user.use_bandwidth
    },

    hasNotification() {
      return this.isLimitReached ||
        this.isNumberVerificationRequired ||
        this.showA2pSubscriptionAlert
    },

    isLimitReached() {
      return this.isBusiness && (
        this.user.business.no_messages && this.user.business.usage_messages >= this.user.business.no_messages * .9 ||
        this.user.business.no_contacts && this.user.business.usage_contacts >= this.user.business.no_contacts * .9
      )
    },

    isNumberVerificationRequired() {
      let statusList = ['completed', 'in-progress', 'pending', 'verified', 'resolved - verified']
      return this.isBusiness && this.isBandwidthUser && 
        (!statusList.includes(this.user.business.number_status) || !this.business.phone_number)
    },

    showA2pSubscriptionAlert() {
      let agency = this.business && this.business.agency
      return this.isBusiness && !this.business.is_tollfree && this.isBandwidthUser && this.user.a2p_subscription_status && !['active', 'trialing'].includes(this.user.a2p_subscription_status) && !this.terminateAt &&
        (!this.business.free_fee && (!agency || !agency.free_fee)) && !this.isNumberVerificationRequired
    },

    terminateAt() {
      let res = null
      if (this.isBusiness && this.user.a2p_subscription_end_at > moment().subtract(7, 'days').format('YYYY-MM-DD')) {
        res = moment(this.user.a2p_subscription_end_at).add(7, 'days').format('MMM Do, h:mm A');
      }
      return res
    },

    notificationData() {
      let data = {
        'status': undefined,
        'msg': ''
      }
      if (this.user && this.user.role === 'Business' && this.user.business && this.user.business.agency) {
        const agencyEmail = this.user.business.agency.email;
        if (this.isNumberVerificationRequired) {
          let msg = 'In order to send text messages to your customer you must register your business to ensure your messages get delivered. <u><a href="javascript:;" id="a2pLink" class="font-weight-bold">Click Here</a></u> to complete the process.'
          data = {
            'status': 'primary',
            'msg': msg
          }
        } else if (this.showA2pSubscriptionAlert) {
          let msg = 'Your SMS registration is currently not active and you won\'t be able to send SMS messages until it is activated. Please contact the <u><a class="font-weight-bold" href="javascript:;" id="a2pSubscriptionLink">admin</a></u> to activate your SMS Registration.'
          data = {
            'status': 'primary',
            'msg': msg
          }
        } else if (this.isLimitReached) {
          if (this.user.business.no_messages && this.user.business.usage_messages >= this.user.business.no_messages * .9) {
            if (this.user.business.usage_messages >= this.user.business.no_messages) {
              data = {
                'status': 'danger',
                'msg': `You have reached your monthly text messaging limit. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
              }
            } else {
              data = {
                'status': 'warning',
                'msg': `You are at 90% of your monthly text message limits. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
              }
            }
          }
          else if (this.user.business.no_contacts && this.user.business.usage_contacts >= this.user.business.no_contacts * .9) {
            if (this.user.business.usage_contacts >= this.user.business.no_contacts) {
              data = {
                'status': 'danger',
                'msg': `You have reached the limit of your total contacts. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
              }
            } else {
              data = {
                'status': 'warning',
                'msg': `You are at 90% of your total contacts allowed on your plan. Contact <a href="mailto:${agencyEmail}" target="_blank">${agencyEmail}</a> to upgrade your account.`
              }
            }
          }
        }
      }
      return data
    },
  },

  mounted() {
    if (this.$route.query.warning === 'trial') {
      this.showWarningTrial = true
    }
  },

  methods: {
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },

    handleNotificationClick(e) {
      let clickedElId = e.target.id
      const agencyEmail = this.user.business.agency.email;
      if (clickedElId === 'a2pLink') {
        this.$router.push({ name: 'business.settings', query: { tab: '2' } })
      }
      if (clickedElId === 'a2pSubscriptionLink') {
        window.open("mailto:" + agencyEmail + '?subject=SMS registration payment failed');
      }
    }
  },
}
</script>