import axios from 'axios'

export default {
  namespaced: true,

  state: {},

  mutations: {
  },

  actions: {
    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('/blacklists', { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },


    create({ commit }, blacklist) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/blacklists`, blacklist)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, blacklist) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/blacklists/${blacklist.id}`, blacklist)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    remove({ commit }, blacklistId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/blacklists/${blacklistId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
  },
}
