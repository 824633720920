import Vue from 'vue'
import axios from 'axios'

const initState = {
  broadcasts: null,
  queries: {
    per_page: 10,
    page: 1,
    search: null,
    sort_by: 'id',
    sort_desc: false,
  },
  total: 0,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { broadcasts, queries, total }) {
      state.broadcasts = broadcasts
      state.queries = queries
      state.total = total
    },

    updateBroadcastSuccess(state) {
      state.broadcasts = null
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },
    
    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('broadcasts', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              broadcasts: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(res.data)
          })
          .catch((err) => reject(err))
      })
    },

    find({ commit }, broadcastId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/broadcasts/${broadcastId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    redemptions({ commit }, { broadcastId, queries }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/broadcasts/${broadcastId}/redemptions`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sentStats({ commit }, { broadcastId, queries }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/broadcasts/${broadcastId}/sent-stats`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('broadcasts', params)
          .then((res) => {
            commit('resetAllSuccess')
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    update({ commit }, broadcast) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/broadcasts/${broadcast.id}`, broadcast)
          .then((res) => {
            commit('updateBroadcastSuccess')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    stop({ commit }, broadcast) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/broadcasts/${broadcast.id}/stop`, broadcast)
          .then((res) => {
            commit('resetAllSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    destroy({ commit }, broadcast) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/broadcasts/${broadcast.id}`, broadcast)
          .then((res) => {
            commit('resetAllSuccess')
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    messageCredit({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/broadcasts/message-credit`, params)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sentTest({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('broadcasts/test', params)
          .then(() => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },
  },

  getters: {
    all(state) {
      return state.broadcasts
    },

    total(state) {
      return state.total
    },

    queries(state) {
      return state.queries
    },
  },
}
