import Vue from 'vue'
import axios from 'axios'

const initState = {
  uploads: null,
  queries: {
    per_page: 10,
    page: 1,
    search: null,
    sort_by: 'id',
    sort_desc: false,
  },
  total: 0,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { uploads, queries, total }) {
      state.uploads = uploads
      state.queries = queries
      state.total = total
    },

    createSuccess(state, upload) {
      this.uploads = null
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },
    
    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('uploads', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              uploads: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    find({ commit }, uploadId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/uploads/${uploadId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/uploads`, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            commit('createSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getToken({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/uploads/${id}/token`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    destroy({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/uploads/${id}`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    image({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/uploads/image`, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.uploads
    },

    total(state) {
      return state.total
    },

    queries(state) {
      return state.queries
    },
  },
}
