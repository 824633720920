import Vue from 'vue'
import axios from 'axios'

const initState = {
  currentPage: 'index',
  campaigns: null,
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, campaigns) {
      state.campaigns = campaigns
    },

    createSuccess(state, campaign) {
      if (state.campaigns) {
        state.campaigns = [...state.campaigns, campaign]
      }
    },

    updateSuccess(state, campaign) {
      if (state.campaigns) {
        state.campaigns = state.campaigns.map((c) =>
          c.id === campaign.id ? campaign : c
        )
      }
    },

    removeSuccess(state, campaignId) {
      let agencyIndex = state.campaigns.findIndex((c) => c.id === campaignId)

      state.campaigns.splice(agencyIndex, 1)
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/campaigns')
          .then((res) => {
            commit('getAllSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/campaigns/${campaignId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, campaign) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/campaigns`, campaign)
          .then((res) => {
            commit('createSuccess', res.data.data)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    update({ commit }, campaign) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/campaigns/${campaign.id}`, campaign)
          .then((res) => {
            commit('updateSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    remove({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/campaigns/${campaignId}`)
          .then((res) => {
            commit('removeSuccess', campaignId)
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    all(state) {
      return state.campaigns
    },
  },
}
