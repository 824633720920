import Vue from 'vue'
import axios from 'axios'

const initState = {
	currentPage: 'index',
	apikeys: null,
	queries: {
		per_page: 10,
		page: 1,
		search: null,
		sort_by: 'id',
		sort_desc: false,
		apikeys: [],
		opt_out: '',
	},
	total: 0,
}

export default {
	namespaced: true,

	state: Vue.util.extend({}, initState),

	mutations: {
		getAllSuccess(state, {
			apikeys,
			queries,
			total
		}) {
			state.apikeys = apikeys
			state.queries = queries
			state.total = total
		},
	},

	actions: {

		getAll({
			commit
		}, queries) {
			return new Promise((resolve, reject) => {
				axios
					.get('/apikeys', {
						params: queries
					})
					.then((res) => {
						commit('getAllSuccess', {
							apikeys: res.data.data,
							queries: queries,
							total: res.data.meta.total,
						})
						resolve(true)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},

		create({
			commit
		}, apikey) {
			return new Promise((resolve, reject) => {
				axios
					.post(`/apikeys`, apikey)
					.then((res) => {
						resolve(res)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},

		update({
			commit
		}, apikey) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/apikeys/${apikey.id}`, apikey)
					.then((res) => {
						resolve(true)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},

		destroy({ commit }, apikeyId) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/apikeys/${apikeyId}`)
					.then((res) => {
						resolve(res)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
	},

	getters: {
		all(state) {
			return state.apikeys
		},

		total(state) {
			return state.total
		},

		queries(state) {
			return state.queries
		},
	},
}