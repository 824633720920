<template>
  <div class="left-side-menu">
    <div class="sidebar-content">
      <div id="sidebar-menu">
        <ul id="side-menu" class="metismenu">
          <li
            v-for="(menuItem, index) in renderMenuItems"
            :key="index"
            class="side-nav-title side-nav-item"
            :class="{ 
              'mm-active': currentRoute.match(menuItem.pathActive),
              'two-way': menuItem.route === 'agency.twoway',
            }"
          >
            <router-link
              v-if="!menuItem.items && menuItem.route"
              :to="{ name: menuItem.route }"
              class="side-nav-link side-nav-link-ref"
              exact-active-class=".side-nav-item"
            >
              <feather :type="menuItem.icon"></feather>
              <span>{{ menuItem.label }}</span>
              <span v-if="menuItem.label === 'Message' && user.business && user.business.unread > 0" class="count-message">
                {{ user.business.unread }}
              </span>
            </router-link>

            <a
              v-if="!menuItem.items && menuItem.routeOut"
              :href="menuItem.routeOut"
              class="side-nav-link"
              target="_blank"
            >
              <feather :type="menuItem.icon"></feather>
              <span>{{ menuItem.label }}</span>
            </a>

            <router-link
              v-if="menuItem.items"
              :to="{ name: menuItem.route }"
              class="side-nav-link"
              :aria-expanded="currentRoute.match(menuItem.pathActive) !== null"
              @click="clickParentItem(menuItem)"
            >
              <feather :type="menuItem.icon"></feather>
              <span>{{ menuItem.label }}</span>
              <span class="menu-arrow"></span>
            </router-link>

            <ul
              v-if="menuItem.items"
              class="nav-second-level mm-collapse"
              :class="{ 'mm-show': currentRoute.match(menuItem.pathActive) }"
            >
              <li v-for="(item, index1) in menuItem.items" :key="index1">
                <router-link :to="{ name: item.route }">{{
                  item.label
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div v-if="showNotification" id="sidebar-menu" style="flex:0;padding-bottom:0;">
        <ul id="side-menu" class="metismenu side-menu-notification" style="margin:0">
          <li
            class="side-nav-title side-nav-item"
            :class="{
              'mm-active': currentRoute.match(
                `${userRole.toLocaleLowerCase()}.notifications.index`
              ),
            }"
          >
            <!-- <router-link
              :to="{
                name: `${userRole.toLocaleLowerCase()}.notifications.index`,
              }"
              class="side-nav-link side-nav-link-ref"
              exact-active-class=".side-nav-item"
            >
              <feather type="bell"></feather>
              <span>Notification</span>
            </router-link> -->
            <a
              href="javascipt:;"
              class="side-nav-link side-nav-link-ref"
              exact-active-class=".side-nav-item"
            >
              <feather type="bell"></feather>
              <span>Notification</span>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="user" class="media user-profile mt-2 mb-2">
        <img :src="user.avatar" class="avatar-sm rounded-circle mr-2" />
        <img :src="user.avatar" class="avatar-xs rounded-circle mr-2" />

        <div class="media-body">
          <h6 class="pro-user-name mt-0 mb-0">{{ user.full_name }}</h6>
          <span class="pro-user-desc">{{ user.role }}</span>
        </div>
        <b-dropdown
          variant="black"
          class="align-self-center"
          toggle-class="p-0"
        >
          <template v-slot:button-content>
            <feather type="chevron-down" class="align-middle"></feather>
          </template>
          <b-dropdown-item v-if="isImpersonating" @click="stopImpersonate">
            <feather type="log-in" class="icon-dual icon-xs mr-2"></feather>
            <span>Main account</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="user && userRole !== 'Business'"
            :to="{ name: `${userRole.toLocaleLowerCase()}.profile` }"
          >
            <feather type="user" class="icon-dual icon-xs mr-2"></feather>
            <span>My Account</span>
          </b-dropdown-item>
          <!-- <b-dropdown-item v-if="user" :to="{ name: `${userRole.toLocaleLowerCase()}.notifications.index` }">
            <feather type="headphones" class="icon-dual icon-xs mr-2"></feather>
            <span>Notification</span>
          </b-dropdown-item> -->

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item class="notify-item p-0" @click.prevent="logout">
            <feather type="log-out" class="icon-dual icon-xs mr-2"></feather>
            <span>Logout</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuRef: null,
      menuItems: {
        Admin: [
          {
            label: 'Dashboard',
            route: 'admin.dashboard',
            icon: 'home',
            pathActive: '/admin/dashboard',
          },
          {
            label: 'Agencies',
            icon: 'briefcase',
            pathActive: '/admin/agencies',
            route: 'admin.agencies.index',
            items: [
              { label: 'List', route: 'admin.agencies.index' },
              { label: 'Archived', route: 'admin.agencies.archived' },
            ],
          },
          {
            label: 'Businesses',
            icon: 'user',
            pathActive: '/admin/businesses',
            route: 'admin.businesses.index',
            items: [
              { label: 'List', route: 'admin.businesses.index' },
              { label: 'Archived', route: 'admin.businesses.archived' },
            ],
          },
          {
            label: 'Whitelabel',
            route: 'admin.whitelabels.index',
            icon: 'globe',
            pathActive: '/admin/whitelabels',
          },
          {
            label: 'Plans',
            icon: 'credit-card',
            route: 'admin.plans.index',
            pathActive: '/admin/plans',
          },
          // {
          //   label: 'Notifications',
          //   icon: 'bell',
          //   route: 'admin.notifications.index',
          //   pathActive: '/admin/notifications',
          // },
          {
            label: 'Business Plans',
            icon: 'list',
            route: 'admin.business-plans.index',
            pathActive: '/admin/business-plans',
          },
          {
            label: 'Reports',
            icon: 'trending-up',
            route: 'admin.reports.index',
            pathActive: '/admin/reports',
          },
          {
            label: 'Uploads',
            icon: 'upload',
            pathActive: '/admin/uploads',
            route: 'admin.uploads.contact',
            items: [
              { label: 'Contact', route: 'admin.uploads.contact' },
            ],
          },
          {
            label: 'Settings',
            route: 'admin.settings',
            icon: 'settings',
            pathActive: '/admin/settings',
            items: [
              { label: 'Settings', route: 'admin.settings' },
              { label: 'A2P', route: 'admin.settings.a2p' },
              { label: 'TollFree', route: 'admin.settings.tollfree' },
            ],
          },
        ],
        Agency: [
          { label: 'Dashboard', route: 'agency.dashboard', icon: 'home', pathActive: '/agency/dashboard' },
          {
            label: 'Businesses',
            icon: 'user',
            pathActive: '/agency/businesses',
            route: 'agency.businesses.index',
            items: [
              { label: 'List', route: 'agency.businesses.index' },
              { label: 'Archived', route: 'agency.businesses.archived' },
            ],
          },
          {
            label: 'Subscription',
            route: 'agency.subscription',
            icon: 'activity',
            pathActive: '/agency/subscription',
          },
          {
            label: 'Payment Methods',
            icon: 'credit-card',
            route: 'agency.payment-methods.index',
            pathActive: '/agency/payment-methods',
          },
          {
            label: 'Two-Way Texting',
            route: 'agency.twoway',
            icon: 'message-square',
            pathActive: '/agency/two-way',
          },
          {
            label: 'Loop Subscription',
            route: 'agency.loop',
            icon: 'refresh-ccw',
            pathActive: '/agency/loop',
          },
          {
            label: 'Training',
            routeOut: 'https://www.customerengine.io/training',
            icon: 'book-open',
            pathActive: '/agency/training',
          },
          {
            label: 'Reports',
            icon: 'trending-up',
            route: 'agency.reports.index',
            pathActive: '/agency/reports',
          },
          {
            label: 'Settings',
            route: 'agency.settings',
            icon: 'settings',
            pathActive: '/agency/settings',
          },
        ],
        Business: [
          {
            label: 'Dashboard',
            route: 'business.dashboard',
            icon: 'home',
            pathActive: '/business/dashboard',
          },
          {
            label: 'Campaigns',
            icon: 'package',
            route: 'business.coupons.index',
            pathActive: '/business/campaigns',
          },
          {
            label: 'Loop Statistics',
            icon: 'refresh-ccw',
            route: 'business.loopStatistics',
            pathActive: '/business/stats-loop',
          },
          {
            label: 'Contacts',
            icon: 'book',
            route: 'business.contacts.index',
            pathActive: '/business/contacts',
          },
          {
            label: 'Broadcasts',
            route: 'business.broadcasts.index',
            icon: 'inbox',
            pathActive: '/business/broadcasts',
          },
          {
            label: 'Automations',
            route: 'business.automations.index',
            icon: 'command',
            pathActive: '/business/automations',
          },
          {
            label: 'Message',
            route: 'business.livechat.index',
            icon: 'message-square',
            pathActive: '/business/livechat',
          },
          {
            label: 'Two-Way Texting',
            route: 'business.twoway',
            icon: 'credit-card',
            pathActive: '/business/two-way',
          },
          {
            label: 'Subscription',
            route: 'business.subscription',
            icon: 'activity',
            pathActive: '/business/subscription',
          },
          {
            label: 'Loop Subscription',
            route: 'business.loop',
            icon: 'refresh-ccw',
            pathActive: '/business/loop',
          },
          {
            label: 'Payment Methods',
            icon: 'credit-card',
            route: 'business.payment-methods.index',
            pathActive: '/business/payment-methods',
          },
          {
            label: 'Settings',
            route: 'business.settings',
            icon: 'settings',
            pathActive: '/business/settings',
            items: [
              { label: 'Keywords', route: 'business.settings.keyword' },
              { label: 'Integrations', route: 'business.settings.integrations' },
              { label: 'Resources', route: 'business.settings.resources' },
              { label: 'Api Keys', route: 'business.settings.apikeys' },
              { label: 'Custom Fields', route: 'business.settings.customFields' },
            ],
          }
        ],
      },
      currentRoute: '',
    }
  },

  computed: {
    renderMenuItems() {
      if (this.user)
      {
        let items = this.menuItems[this.user.role]

        if (this.user.role === 'Agency') {
          items = items.filter(
            (i) =>
              !this.user.agency.free_fee ||
              (this.user.agency.free_fee &&
                i.label !== 'Subscription' &&
                i.label !== 'Payment methods')
          )

          if (this.user.agency.is_template) {
            items = items.filter(i => ['Businesses', 'Settings'].includes(i.label))
          }

          items = items.filter(i => !this.user.agency.is_active_livechat || this.user.agency.is_active_livechat && i.route !== 'agency.twoway')

          items = items.filter(i => !this.user.agency.is_active_loop || this.user.agency.is_active_loop && i.route !== 'agency.loop')
        }

        if (this.user.role === 'Business') {
          if (!this.user.business.is_enabled_livechat && (this.user.business.agency && !this.user.business.agency.is_enabled_livechat || !this.user.business.is_active_livechat))
          {
            items = items.filter(
              (i) => i.label !== 'Message'
            )
          }

          if (!this.user.business.is_template && !this.user.business.template_content) {
            let settingItemIndex = items.findIndex(i => i.label === 'Settings')
            if (settingItemIndex !== -1)
            {
              items[settingItemIndex].items = items[settingItemIndex].items.filter(i => !['Resources'].includes(i.label))
            }
          }

          if (!this.user.business.is_enabled_loop && (this.user.business.agency && !this.user.business.agency.is_enabled_loop || !this.user.business.is_active_loop))
          {
            items = items.filter(
              (i) => i.label !== 'Loop Statistics'
            )
          }

          if (this.user.business.agency) {
            items = items.filter(i => !['Two-Way Texting', 'Subscription', 'Payment Methods', 'Loop Subscription'].includes(i.label))
          } else {
            items = items.filter(
              (i) =>
                !this.user.business.free_fee ||
                (this.user.business.free_fee &&
                  i.label !== 'Subscription' &&
                  i.label !== 'Payment methods')
            )

            items = items.filter(i => !this.user.business.is_active_livechat || this.user.business.is_active_livechat && i.route !== 'business.twoway')

            items = items.filter(i => !this.user.business.is_active_loop || this.user.business.is_active_loop && i.route !== 'business.loop')
          }
        }

        return items
      }
      return []
    },

    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    showNotification() {
      return this.userRole === 'Agency'
    },

    userRole() {
      return this.user ? this.user.role : ''
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    this.currentRoute = this.$router.currentRoute.path

    this.$router.afterEach((to, from) => {
      this.currentRoute = to.path
    })
    if (this.showNotification)
    {
      this.install_notification_script();
    }
  },

  beforeDestroy() {
    this.destroy_notification_script();
  },

  methods: {
    install_notification_script() {
      const widgetWrapper = document.getElementById('beamer-script-container')

      if (widgetWrapper)
        return true;

      if (!document.getElementById('beamer-script-container'))
      {
        let body = document.getElementsByTagName('body')[0];
        let div = document.createElement('div');
        div.setAttribute('id', 'beamer-script-container');
        body.appendChild(div);
      }

      let script = document.createElement('script')

      window.beamer_config = {
        product_id : 'sadpmXoz25149',
        selector : '.side-menu-notification',
        lazy: true,
        user_firstname : this.user && this.user.first_name || '',
        user_lastname : this.user && this.user.last_name || '',
        user_email : this.user && this.user.email || '',
        user_id : this.user && this.user.id || '',
      };

      script.type = 'text/javascript'
      script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
      script.onload = () => {
        window.Beamer && window.Beamer.init()
      }
      document.getElementById('beamer-script-container').appendChild(script);    
    },

    destroy_notification_script() {
      const widgetWrapper = document.getElementById('beamerOverlay');
      const scriptWrapper = document.getElementById('beamer-script-container');
      widgetWrapper && widgetWrapper.remove();
      scriptWrapper && scriptWrapper.remove();
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + this.userRole.toLocaleLowerCase())
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },

    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
.slimscroll-menu {
  height: 100%;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.left-side-menu {
  padding-bottom: 10px;
}
.left-side-menu .sidebar-content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}
#sidebar-menu {
  flex: 1 1 auto;
  .count-message {
    display: inline-block !important;
    position: absolute;
    background: red;
    right: 0px;
    top: 0;
    color: #FFF;
    z-index: 2;
    padding: 0px 6px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    padding-left: 6px !important;
  }
}
</style>
