import Vue from 'vue'
import axios from 'axios'
import moment from 'moment-timezone'

const initState = {
  couponOriginal: {
    name: null,
    type: 'popup',
    keyword: null,
    reminder_message:
      'Thank you for requesting our offer from [company], your redemption code is [dynamic_code] and the offer expires on [expiration_date].',
    day_to_expire: 14,
    reminders: [
      // {
      //   days: 3,
      //   time: '09:00',
      //   message:
      //     "Looks like you haven't redeemed your buy one pizza get one free offer from [company] and the code expires on [date]. See you soon.",
      // },
      // {
      //   days: 1,
      //   time: '09:00',
      //   message:
      //     '[company] here and wanted to let you know your buy one pizza get one free code expires on [date].',
      // },
    ],
    contact_notification: false,
    notification_lead_type: false,
    notifications: [],
    sections: {
      styling: {
        BackgroundColor: '#FFFFFF',
        BorderColor: null,
        Roudness: 8,
        MaxWidth: 320,
      },
      logo: {
        Image: null,
        Link: null,
        MaxWidth: 250,
        Align: 'center',
      },
      heroimage: {
        Image: null,
        MaxWidth: 300,
        Align: 'center',
      },
      headline: {
        Content: null,
        Color: '#333333',
        FontSize: 22,
        Align: 'center',
        FontWeight: 'initial',
        FontStyle: 'initial',
      },
      subheading: {
        Content:
          'Enter your mobile number below and we will text you your coupon code.',
        MobileContent:
          'Click the button below, text the keyword <keyword> to us and we will send you your coupon code.',
        PrintContent:
          'Text the keyword <keyword> to <phone number> and we will send you your coupon code.',
        Color: '#333333',
        FontSize: 16,
        Align: 'center',
        FontWeight: 'initial',
        FontStyle: 'initial',
      },
      inputs: {
        BackgroundColor: '#FFFFFF',
        BorderColor: '#CED4DA',
        Roudness: 4,
        Color: '#333333',
        FontSize: 14,
        ShowName: false,
        NameRequired: true,
        NamePlaceholder: 'Enter Your Name',
        ShowEmail: false,
        EmailRequired: true,
        EmailPlaceholder: 'Enter Your Email',
        ShowPhone: true,
        PhoneRequired: true,
        PhonePlaceholder: 'Enter your Number',
      },
      mainbutton: {
        BackgroundColor: '#2399F0',
        BorderColor: '#2399F0',
        Roudness: 8,
        Label: 'Send my coupon',
        Color: '#FFFFFF',
        FontSize: 14,
        Number: null,
        Type: 'icon-left',
        Icon: 'uil uil-envelope-alt',
      },
      mobilebutton: {
        BackgroundColor: '#2399F0',
        BorderColor: '#2399F0',
        Roudness: 8,
        Label: 'Send my coupon',
        Color: '#FFFFFF',
        FontSize: 14,
        Number: null,
        Type: 'icon-left',
        Icon: 'uil uil-envelope-alt',
      },
      triggerButton: {
        BackgroundColor: '#2399F0',
        BorderColor: '#2399F0',
        Roudness: 8,
        Label: 'Check Out Our Offer Here!',
        Color: '#FFFFFF',
        FontSize: 16,
        Number: null,
        Type: 'icon-left',
        Icon: 'uil uil-comment',
      },
      legalFooter: {
        Content:
          'By submitting this form and signing up for texts, you consent to receive marketing text messages (e.g. promos, cart reminders) from [company name] at the number provided, including messages sent by autodialer. Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available).',
        Color: '#001b488a',
        FontSize: 10,
        Align: 'center',
      },
    },
  },
  coupons: null,
  discountTypes: [
    'Percentage Discount',
    'Money Discount',
    'Buy One Get One Free',
    'Free Offer',
    'No Offer - Info Only',
  ],
  couponTypes: [
    { id: 1, label: 'Dynamic' },
    { id: 2, label: 'Static' },
  ],
  queries: {
    per_page: 10,
    page: 1,
    search: null,
    sort_by: 'id',
    sort_desc: false,
  },
  total: 0,
  popupOriginal: {
    title: null,
    description: null,
    keyword: null,
    type: 'modal',
    sections: {
      styling: {
        BackgroundColor: '#FFFFFF',
        Roudness: 14,
        MaxWidth: 780,
        BorderColor: '#FFFFFF00',
        BorderWidth: 0,
        MinHeight: 500,
        Template: 'default',
        TemplateColor: '#284472',
        LineWidth: 0,
        Align: 'left',
      },
      settings: {
        Timeout: 10,
        Duration: 5,
        Period: 'day(s)',
        PopUpOnExit: false,
        PopUpOnExitDuration: 1,
        PopUpOnExitPeriod: 'day(s)',
        IsScheduled: false,
        TimeFrom: '09:00',
        TimeTo: '17:00',
        Timezone: moment.tz.guess(),
      },
      emailStep: {
        Content: {
          BackgroundImg: 'https://images.unsplash.com/photo-1453614512568-c4024d13c247?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMzY3ODV8MHwxfHNlYXJjaHwxfHxjb2ZmZWUlMjBzaG9wfGVufDB8MHx8fDE2ODExNTQyMzA&ixlib=rb-4.0.3&q=80&w=1080',
          Title: 'Get 10% Off',
          Description: 'Save on your first order and get email only offers when you join.',
          DescriptionColor: '#000000',
          DescriptionFontSize: 18,
          DescriptionAlign: 'center',
          Color: '#000000',
          FontSize: 40,
          Align: 'center',
          FontWeight: 'initial',
          FontStyle: 'initial',
          PaddingX: 20,
          PaddingY: 20,
          Template: 'default',
        },
        Input: {
          BackgroundColor: '#FFFFFF',
          IsRequired: true,
          Color: '#000000',
          BorderColor: '#000000',
          Roudness: 5,
          Placeholder: 'Enter your email',
          MarginTop: 0,
        },
        Button: {
          BackgroundColor: '#000000',
          BorderColor: '#000000',
          Roudness: 5,
          Label: 'Continue',
          Color: '#FFFFFF',
          FontSize: 20,
          Padding: 10,
          MarginTop: 0,
        }
      },
      phoneStep: {
        IsEnable: true,
        Content: {
          BackgroundImg: 'https://images.unsplash.com/photo-1445116572660-236099ec97a0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMzY3ODV8MHwxfHNlYXJjaHw1fHxjb2ZmZWUlMjBzaG9wfGVufDB8MHx8fDE2ODEzMTAyMjY&ixlib=rb-4.0.3&q=80&w=1080',
          Title: 'Want SMS Only Deals?',
          Description: "Enter your mobile number to get deals you won't find anywhere else.",
          DescriptionColor: '#000000',
          DescriptionFontSize: 18,
          DescriptionAlign: 'center',
          Legal: 'By submitting this form and signing up for texts, you consent to receive marketing text messages (e.g. promos, cart reminders) from [company name] at the number provided, including messages sent by autodialer. Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available).',
          LegalColor: '#000000',
          LegalFontSize: 10,
          LegalMarginTop: 5,
          LegalAlign: 'center',
          Color: '#000000',
          FontSize: 40,
          Align: 'center',
          FontWeight: 'initial',
          FontStyle: 'initial',
          PaddingX: 20,
          PaddingY: 20,
        },
        Input: {
          BackgroundColor: '#FFFFFF',
          Color: '#000000',
          BorderColor: '#000000',
          Roudness: 5,
          FontSize: 19,
          Placeholder: 'Enter your phone',
          MarginTop: 10,
        },
        Button: {
          BackgroundColor: '#000000',
          BorderColor: '#000000',
          Roudness: 10,
          Label: 'Join Now!',
          Color: '#FFFFFF',
          FontSize: 22,
          Padding: 10,
          MarginTop: -3,
        },
        NoThanks: {
          Label: 'No, I Don\'t Want Texts',
          FontSize: 16,
          Color: '#000000',
          MarginTop: 10,
        }
      },
      closeButton: {
        BackgroundColor: '#FFFFFF',
        BorderColor: '#919191FF',
        Roudness: 0,
        Color: '#000000',
        Size: 21,
        Top: 8,
        Right: 8
      },
      triggerButton: {
        BackgroundColor: '#000000',
        BorderColor: '#000000',
        Roundness: 5,
        Label: 'Get 10% Off',
        Color: '#FFFFFF',
        FontSize: 18,
        Padding: 10,
        Type: 'icon-left',
        Icon: 'uil uil-comment',
        IsEnable: true,
        Position: 'right',
      },
      thanksStep: {
        BackgroundImg: 'https://images.unsplash.com/photo-1525610553991-2bede1a236e2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMzY3ODV8MHwxfHNlYXJjaHwyN3x8Y29mZmVlJTIwc2hvcHxlbnwwfDB8fHwxNjgxMTU0OTQ2&ixlib=rb-4.0.3&q=80&w=1080',
        Content: 'Thanks For Signing Up',
        PaddingX: 20,
        PaddingY: 20,
        FontSize: 34,
        Color: '#000000',
        Align: 'center',
        Desc: 'Check your mobile device to confirm your SMS subscription.',
        DescAlign: 'center',
        DescColor: '#000000',
        DescFontSize: 18,
        DescMarginTop: 0,
        RedirectURI: undefined,
      },
    },
  },
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    createSuccess(state) {
      state.coupons = null
    },

    getAllSuccess(state, { coupons, queries, total }) {
      state.coupons = coupons
      state.queries = queries
      state.total = total
    },

    updateSuccess(state) {
      state.coupons = null
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    getAll({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('coupons', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              coupons: res.data.data,
              queries: queries,
              total: res.data.meta.total,
            })
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    getAllLoops({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('coupons/loops')
          .then((res) => {
            commit('getAllSuccess', {
              coupons: res.data.data,
            })
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    getStats({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios
          .get('coupons/stats', { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    getKeywords({ commit }, queries = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get('coupons/keywords', { params: queries })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    getKeywordsWithoutDemo() {
      return new Promise((resolve, reject) => {
        axios
          .get('coupons/keywords', { params: { is_sample: 0 }})
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    find({ commit }, couponId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`coupons/${couponId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    findByUniqueId({ commit }, uniqueId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/coupons/${uniqueId}/unique-id`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    create({ commit }, coupon) {
      return new Promise((resolve, reject) => {
        axios
          .post('coupons', coupon)
          .then((res) => {
            commit('createSuccess')
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    getScript({ commit }, couponId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`coupons/${couponId}/script`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    sendEmail({ commit }, { couponId, email }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`coupons/${couponId}/send-email`, { email: email })
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    update({ commit }, coupon) {
      return new Promise((resolve, reject) => {
        axios
          .put(`coupons/${coupon.id}`, coupon)
          .then((res) => {
            commit('updateSuccess')
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    updateSetting({ commit }, parmas) {
      const { id, data } =  parmas
      return new Promise((resolve, reject) => {
        axios
          .put(`coupons/${id}/update-setting`, data)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    destroy({ commit }, couponId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`coupons/${couponId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    uploadImage({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`coupons/upload-image`, data)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    getUnsplashImages({ commit }, { query, page }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `https://api.unsplash.com/search/photos?per_page=12&orientation=landscape&query=${query}&page=${page}`,
            {
              headers: {
                Authorization: `Client-ID ${
                  process.env.VUE_APP_UNSPLASH_ACCESS_TOKEN
                }`,
              },
            }
          )
          .then((res) => {
            resolve(res.data.results)
          })
          .catch((err) => reject(err))
      })
    },

    redemption({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`coupons/redemption`, params)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    redeem({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`coupons/${params.id}/redeem`, params)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    findByStaticCode({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`coupons/${params.uniqueId}/static-code`, params)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    findByCode({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`coupons/${params.uniqueId}/code/${params.code}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((err) => reject(err))
      })
    },

    clone({ commit }, couponId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`coupons/${couponId}/clone`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    duplicate({ commit }, uniqueId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/coupons/${uniqueId}/duplicate`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    couponOriginal(state) {
      return state.couponOriginal
    },

    all(state) {
      return state.coupons
    },

    discountTypes(state) {
      return state.discountTypes
    },

    couponTypes(state) {
      return state.couponTypes
    },

    total(state) {
      return state.total
    },

    queries(state) {
      return state.queries
    },

    popupOriginal(state) {
      return state.popupOriginal
    },
  },
}
