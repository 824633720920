import lazyLoading from './lazyLoading'
import DashboardLayout from '@layouts/Dashboard'
import AdminGuard from './guards/admin-guard'

export default [
  {
    path: '/admin/',
    component: DashboardLayout,
    beforeEnter: AdminGuard,
    redirect: { name: 'admin.agencies.index' },
    children: [
      {
        name: 'admin.dashboard',
        path: 'dashboard',
        component: lazyLoading('Admin/Dashboard'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.users.index',
        path: 'users',
        component: lazyLoading('Admin/Users/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.index',
        path: 'agencies',
        component: lazyLoading('Admin/Agencies/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.archived',
        path: 'agencies/archived',
        component: lazyLoading('Admin/Agencies/Archived'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.create',
        path: 'agencies/create',
        component: lazyLoading('Admin/Agencies/Create'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.agencies.edit',
        path: 'agencies/:id/edit',
        component: lazyLoading('Admin/Agencies/Edit'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.businesses.create',
        path: 'businesses/create',
        component: lazyLoading('Admin/Businesses/Create'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.businesses.index',
        path: 'businesses',
        component: lazyLoading('Admin/Businesses/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.businesses.archived',
        path: 'businesses/archived',
        component: lazyLoading('Admin/Businesses/Archived'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.businesses.edit',
        path: 'businesses/:id/edit',
        component: lazyLoading('Admin/Businesses/Edit'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.profile',
        path: 'profile',
        component: lazyLoading('Common/Profile'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.whitelabels.index',
        path: 'whitelabels',
        component: lazyLoading('Admin/Whitelabels/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.plans.index',
        path: 'plans',
        component: lazyLoading('Admin/Plans/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.plans.edit',
        path: 'plans/:id/edit',
        component: lazyLoading('Admin/Plans/Edit'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.plans.create',
        path: 'plans/create',
        component: lazyLoading('Admin/Plans/Create'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.notifications.index',
        path: 'notifications',
        component: lazyLoading('Common/Notification'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.notifications.create',
        path: 'notifications/create',
        component: lazyLoading('Admin/Notifications/Create'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings',
        path: 'settings',
        component: lazyLoading('Admin/Settings/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings.a2p',
        path: 'settings/a2p',
        component: lazyLoading('Admin/Settings/A2p'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings.a2p.edit',
        path: 'settings/a2p/:id/edit',
        component: lazyLoading('Admin/Settings/Components/A2pDetail'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings.tollfree',
        path: 'settings/tollfree',
        component: lazyLoading('Admin/Settings/Tollfree'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.settings.tollfree.edit',
        path: 'settings/tollfree/:id/edit',
        component: lazyLoading('Admin/Settings/Components/TollfreeDetail'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.business-plans.index',
        path: 'business-plans',
        component: lazyLoading('Admin/BusinessPlans/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.business-plans.edit',
        path: 'business-plans/:id/edit',
        component: lazyLoading('Admin/BusinessPlans/Edit'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.business-plans.create',
        path: 'business-plans/create',
        component: lazyLoading('Admin/BusinessPlans/Create'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.reports.index',
        path: 'reports',
        component: lazyLoading('Admin/Reports/Index'),
        beforeEnter: AdminGuard,
      },
      {
        name: 'admin.uploads.contact',
        path: 'uploads/contact',
        component: lazyLoading('Admin/Uploads/Contact'),
        beforeEnter: AdminGuard,
      },
    ],
  },
]
