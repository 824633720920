import 'vue-toast-notification/dist/theme-default.css';

import Vue from 'vue'
import App from './app'
import store from './store/index'
import router from './router/index'
import BootstrapVue from 'bootstrap-vue'
import VueFeather from 'vue-feather'
import flatPickr from 'vue-flatpickr-component'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import './configs/http'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueToast from 'vue-toast-notification';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import '@fortawesome/fontawesome-free/css/all.css';
import vClickOutside from 'v-click-outside'

// import * as Sentry from '@sentry/browser'
// import { Vue as VueIntegration } from '@sentry/integrations'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import VueCryptojs from 'vue-cryptojs'
import linkify from 'vue-linkify'
import _ from 'lodash';

// Filters
import './filters/currency'
import './filters/number'
import './filters/datetime'
import './filters/strlimit'
import './filters/nationalNumber'
import './filters/internationalNumber'
import './filters/shorttime'
import './filters/couponType'
import './filters/capitalize'
import './filters/legal'

Vue.mixin({
  methods: {
    encrypt(str)
    {
      return this.$CryptoJS.AES.encrypt(str, process.env.VUE_APP_PASSWORD).toString()
    },
    decrtyp(str)
    {
      return this.$CryptoJS.AES.decrypt(str, process.env.VUE_APP_PASSWORD).toString(this.$CryptoJS.enc.Utf8)
    },
    formatNational: (val) => {
      if (!val) return "";
      if (!val.startsWith("+")) val = "+" + val;

      const phoneNumber = parsePhoneNumberFromString(val);
      if (phoneNumber)
      {
        if (['US', 'CA'].includes(phoneNumber.country))
        {
          return phoneNumber.formatNational()
        } else {
          return phoneNumber.formatInternational()
        }
      }
      return val
    },
    formatRawNumber: (val) => {
      return val.replace(/[^0-9+]/g, '');
    },
    fileSize: (fileSizeInBytes) => {
      var i = -1;
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);
  
      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    },
    isImageFile: (type) => {
      const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/avif', 'image/svg', 'gif', 'jpeg', 'jpg', 'png', 'webp', 'avif', 'svg'];
 
      return type && acceptedImageTypes.includes(type)
    },
    randomStr: (length) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    difference(object, base) {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (base)
          {
            let baseValue = base[key];
            if (key === 'message') {
              if (value)
                value = value.replace( /(<([^>]+)>)/ig, '').replace(/[^a-zA-Z0-9 ]/gi, '').trim();
              if (baseValue)
                baseValue = baseValue.replace( /(<([^>]+)>)/ig, '').replace(/[^a-zA-Z0-9 ]/gi, '').trim();
            }
            if (!_.isEqual(value, baseValue) && key !== 'level') {
              let tmp = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
              if (!_.isEmpty(tmp)) {
                result[key] = tmp
              }
            }
          } else {
            result[key] = value
          }
        });
      }
      return changes(object, base);
    },
  }
});

Vue.config.productionTip = process.env.NODE_ENV === 'development'
Vue.config.devtools = process.env.NODE_ENV === 'development'

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('customurl', {
  validate: (value) => {
    return (
      !value.trim().startsWith('www.') &&
      !value.trim().startsWith('http') &&
      value.match(
        /^[a-zA-Z0-9|-]{1,61}[a-zA-Z0-9|-](?:\.[a-zA-Z0-9|-]{2,})+$/
      )
    )
  },
  message: 'Domain format is invalid.',
})

extend('subdomain', {
  validate: (value) => {
    return (
      value &&
      value.length < 63 &&
      value.match(/^[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/)
    )
  },
  message: 'Subdomain format is invalid.',
})

extend('include', {
  params: ['word'],
  validate: (value, { word }) => {
    return value.trim().indexOf(word) !== -1
  },
  message: `The field requied {word}`,
})

Vue.use(vueFilterPrettyBytes)
Vue.use(VueCryptojs)
Vue.directive('linkified', linkify)
Vue.use(VueFeather)
Vue.use(flatPickr)
Vue.use(VueMoment, { moment })
Vue.use(VueToast, {
  position: 'bottom'
});
Vue.use(vClickOutside)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(BootstrapVue)

// Components
Vue.component('BirthdayInput', () => import(`@components/Common/BirthdayInput.vue`))
Vue.component('PhoneInput', () => import(`@components/Common/PhoneInput.vue`))
Vue.component('TextInput', () => import(`@components/Common/TextInput.vue`))
Vue.component('TextArea', () => import(`@components/Common/TextArea.vue`))
Vue.component('TextAreaAction', () => import(`@components/Common/TextAreaAction.vue`))
Vue.component('TextEditorAction', () => import(`@components/Common/TextEditorAction.vue`))
Vue.component('SelectInput', () => import(`@components/Common/SelectInput.vue`))
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('TimePicker', () => import(`@components/Common/TimePicker.vue`))
Vue.component('UploadImage', () => import(`@components/Common/UploadImage.vue`))
Vue.component('UploadFile', () => import(`@components/Common/UploadFile.vue`))
Vue.component('NumberInput', () => import(`@components/Common/NumberInput.vue`))
Vue.component('MergeFields', () => import(`@components/Common/MergeFields.vue`))
Vue.component('MergeFieldsGroup', () => import(`@components/Common/MergeFieldsGroup.vue`))

// Sentry.init({
//   environment: process.env.NODE_ENV || 'development',
//   dsn: process.env.VUE_APP_SENTRY_DNS,
//   integrations: [new VueIntegration({ Vue, attachProps: true })],
// })

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
